/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { action, autorun, makeObservable } from 'mobx';
import storage from 'store';
import { configureMobX } from '@/common/core/store/configure';

export type NonPersistentStoreDecoratorOptions = {
  persist?: false;
}

export type PersistentStoreDecoratorOptions = {
  name: string;
  persist: true;
};

export type StoreDecoratorOptions =
  | NonPersistentStoreDecoratorOptions
  | PersistentStoreDecoratorOptions;

/**
 * classをMobXのStore化するデコレーター
 *
 * @param options Store化オプション
 * @param options.persist ローカルストレージに永続化するかどうか？
 * @param options.name 永続化するローカルストレージのキー
 */
export const store: (options?: StoreDecoratorOptions) => Function = (
  options = {},
) => <T extends { new (...args: any[]): {} }>(
  constructor: T,
): Function => {
  class PersistentStore extends constructor {
    constructor(...args: any[]) {
      super(args);
      configureMobX();
      makeObservable(this);

      const {
        persist = false, // デフォルトは永続化しない
      } = options;

      let initialized = false;
      if (persist) {
        const { name } = options as PersistentStoreDecoratorOptions;
        autorun(() => {
          if (!initialized) {
            this.restore(name);
          }
          storage.set(name, this);
          initialized = true;
        });
      } else {
        initialized = true;
      }
    }

    @action
    private restore = (name: PersistentStoreDecoratorOptions['name']): void => {
      let persisted = storage.get(name) ?? {};
      persisted = Object.keys(persisted).reduce((obj, key) => (
        Object.prototype.hasOwnProperty.call(this, key) ? { ...obj, [key]: persisted[key] } : obj
      ), {});
      Object.assign(this, persisted);
    };
  }
  return PersistentStore;
};
