/* eslint-disable @typescript-eslint/no-namespace */
namespace Log {
  /**
   * traceログ
   * デバッグモードが有効で、console.log()が使える時に、
   * コンソールに文字列を出力します。
   * @param {string[]} ...args 出力したい文字列です。
   */
  export function trace(msg: string): void {
    if (
      process.env.APP_DEBUG === 'true'
      && window.console
      && typeof window.console.log !== 'undefined'
    ) {
      window.console.log(msg);
    }
  }

  /**
   * errorログ
   * デバッグモードが有効の場合、console.errorで文字列出力。
   * デバッグモードが無効の場合、サーバー通信
   * @export
   * @param {Error} err
   */
  export function error(err: Error): void {
    if (
      process.env.APP_DEBUG === 'true'
      && window.console
      && typeof window.console.error !== 'undefined'
    ) {
      window.console.error(err.stack);
    } else {
      // todo
    }
  }

  /**
   * errorEventログ
   * デバッグモードが有効の場合、console.errorで文字列出力。
   * デバッグモードが無効の場合、サーバー通信
   * @export
   * @param {ErrorEvent} e
   */
  export function errorEvent(e: ErrorEvent): void {
    if (
      process.env.APP_DEBUG === 'true'
      && window.console
      && typeof window.console.error !== 'undefined'
    ) {
      window.console.error(
        `${e.message
        }|${
          e.filename
        }[${
          e.lineno
        }-${
          e.colno
        }]${
          e.error}`,
      );
    } else {
      // todo
    }
  }
}
export default Log;
