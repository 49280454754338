/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { GatsbyBrowser, RouteUpdateArgs, WrapRootElementNodeArgs } from 'gatsby';
import { GTM } from '@/common/util/gtm';
import ContextProvider from '@/newcar/ui/common/context/ContextProvider';

export const wrapRootElement = ({ element }: WrapRootElementNodeArgs): React.ReactElement => (
  <ContextProvider>{element}</ContextProvider>
);

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }: RouteUpdateArgs & { prevLocation: Location }) => {
  // ページタイトルが確実に変わってから実行するため、timeoutを設定
  // 参考: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-google-tagmanager
  setTimeout(() => {
    // SPA用GAページビュー計測イベント
    GTM.dataLayer.push({
      event: 'gaPageView',
      currentPageFullPath: location.href.replace(location.origin, ''),
      currentPagePath: location.pathname,
    });

    // 仮想ページビュー（初回ページビューは無視）
    if (prevLocation) {
      GTM.dataLayer.push({
        event: 'virtualPageView',
        currentPageFullPath: location.href.replace(location.origin, ''),
        currentPagePath: location.pathname,
      });
    }

    // Hubspot トラッキングAPI
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let _hsq = [];
    if (window) {
      _hsq = (window as any)._hsq = (window as any)._hsq || [];
    }

    _hsq.push(['setPath', `${location.pathname}${location.search}${location.hash}`]);
    _hsq.push(['trackPageView']);

    // オプティマイズのカスタムイベント
    GTM.dataLayer.push({
      event: 'optimize.activate',
    });
  }, 50);
};
