/* eslint-disable no-useless-escape */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-shadow */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */

import NEWCAR_CONST from '@/newcar/util/const';

/** カルモ定数 */
namespace CARMO_CONST {

  /** バリデーション正規表現 */
  export namespace REGEX {
    /** 電話番号 */
    export const PHONE = /^0\d+$/;
    /** 携帯電話番号 */
    export const MOBILE_PHONE = /^0[5789]0/;
    /** 固定電話番号 */
    export const HOME_PHONE = /^0[5]0/;
    /** カタカナ */
    export const KANA = /^[ァ-ンヴー]+$/;
    /** 名義人カタカナ */
    export const HOLDER_KANA = /^[^ァ-ンヴー]*$/;
    /** 全角カナ英数字 */
    export const ZENKAKU_KANA_ALPHABET_NUMBER = /^[ァ-ンヴー０-９ａ-ｚＡ-Ｚ　]+$/;
    /** 郵便番号 */
    export const POSTAL_CODE = /^\d{3}-?\d{4}$/;
    /** 免許証番号 */
    export const LICENSE_NUMBER = /^\d{4}-?\d{4}-?\d{4}$/;
    export const LICENSE_NUMBER_NO_HYPHEN = /^\d{12}$/;
    /** 住所 */
    export const ADDRESS_END = /[A-Za-zａ-ｚＡ-Ｚぁ-んァ-ヶ一-龠０-９0-9々〇]$/;
    /** 住所 */
    export const ADDRESS = /[A-Za-zａ-ｚＡ-Ｚぁ-んァ-ヶ一-龠０-９0-9々〇ー-]+$/;
    /** メールアドレス */
    export const MAIL_PIRIOD = /@\w+$/;
    export const MAIL_INVALID_CHARACTERS = /[^a-zA-Z0-9.!#$%&\'*+\/=?^_`{|}~\-@]+/;
    export const MAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /** 社名カナ */
    export const OFFICE_KANA = /^[ァ-ンヴー＆＇，ー．・]+$/;
    /** 数字 */
    export const NUMBER = /^\d+$/;
    /** 氏名禁止ワード */
    export const UNUSABLE_NAME_WORD = /^(?!.*(会社|法人)).*$/;
  }

  export namespace GENDER {
    export const MALE = {
      ID: 'gender_1',
      VALUE: 1,
      LABEL: '男性',
    };
    export const FEMALE = {
      ID: 'gender_2',
      VALUE: 2,
      LABEL: '女性',
    };
    export const GENDER_LIST = [MALE, FEMALE];
    export function findGenderByValue(
      value?: number | null,
    ): typeof MALE | undefined {
      return GENDER_LIST.find((g) => g.VALUE === value);
    }
  }

  export namespace SELECT {
    export const SHOUWA_LAST_DAY = 7;
    export const SHOUWA_LAST_YEAR = 1988;
  }

  /**
   * カンマ付けにフォーマット
   * @export
   * @param {number} price
   * @returns {string}
   */
  export function COMMA_FORMAT(price?: number): string {
    if (price === undefined) {
      return '';
    }
    return String(price).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  export namespace PREFECTURES {
    export const DATA = [
      '北海道',
      '青森県',
      '岩手県',
      '宮城県',
      '秋田県',
      '山形県',
      '福島県',
      '茨城県',
      '栃木県',
      '群馬県',
      '埼玉県',
      '千葉県',
      '東京都',
      '神奈川県',
      '新潟県',
      '富山県',
      '石川県',
      '福井県',
      '山梨県',
      '長野県',
      '岐阜県',
      '静岡県',
      '愛知県',
      '三重県',
      '滋賀県',
      '京都府',
      '大阪府',
      '兵庫県',
      '奈良県',
      '和歌山県',
      '鳥取県',
      '島根県',
      '岡山県',
      '広島県',
      '山口県',
      '徳島県',
      '香川県',
      '愛媛県',
      '高知県',
      '福岡県',
      '佐賀県',
      '長崎県',
      '熊本県',
      '大分県',
      '宮崎県',
      '鹿児島県',
      '沖縄県',
    ];
    export const DATA_KANA = [
      'ホッカイドウ',
      'アオモリケン',
      'イワテケン',
      'ミヤギケン',
      'アキタケン',
      'ヤマガタケン',
      'フクシマケン',
      'イバラキケン',
      'トチギケン',
      'グンマケン',
      'サイタマケン',
      'チバケン',
      'トウキョウト',
      'カナガワケン',
      'ニイガタケン',
      'トヤマケン',
      'イシカワケン',
      'フクイケン',
      'ヤマナシケン',
      'ナガノケン',
      'ギフケン',
      'シズオカケン',
      'アイチケン',
      'ミエケン',
      'シガケン',
      'キョウトフ',
      'オオサカフ',
      'ヒョウゴケン',
      'ナラケン',
      'ワカヤマケン',
      'トットリケン',
      'シマネケン',
      'オカヤマケン',
      'ヒロシマケン',
      'ヤマグチケン',
      'トクシマケン',
      'カガワケン',
      'エヒメケン',
      'コウチケン',
      'フクオカケン',
      'サガケン',
      'ナガサキケン',
      'クマモトケン',
      'オオイタケン',
      'ミヤザキケン',
      'カゴシマケン',
      'オキナワケン',
    ];
  }

  /** メーカー */
  export namespace MAKER {
    /** ID
     * @export LEXUS:1,TOYOTA:2,NISSAN:3,HONDA:4,MAZDA:5,SUBARU:6,SUZUKI:7,DAIHATSU:8,MITSUBISHI:9
     * @enum {number}
     */
    export enum ID {
      /** 未選択 */
      UNSELECTED = 0,
      /** レクサス */
      LEXUS = 1,
      /** トヨタ */
      TOYOTA = 2,
      /** 日産 */
      NISSAN = 3,
      /** ホンダ */
      HONDA = 4,
      /** マツダ */
      MAZDA = 5,
      /** スバル */
      SUBARU = 6,
      /** スズキ */
      SUZUKI = 7,
      /** ダイハツ */
      DAIHATSU = 8,
      /** 三菱 */
      MITSUBISHI = 9,
    }

    /** データ構造
     * 配列順序がリスト表示の順番に相当
     */
    export const DATA = [
      {
        id: ID.UNSELECTED,
        name: '',
        key: '',
        isUnselected: true,
        img: '',
      },
      {
        id: ID.LEXUS,
        name: 'レクサス',
        key: 'lexus',
        img: 'lexus.png',
      },
      {
        id: ID.TOYOTA,
        name: 'トヨタ',
        key: 'toyota',
        img: 'toyota.png',
      },
      {
        id: ID.NISSAN,
        name: '日産',
        key: 'nissan',
        img: 'nissan.png',
      },
      {
        id: ID.HONDA,
        name: 'ホンダ',
        key: 'honda',
        img: 'honda.png',
      },
      {
        id: ID.MAZDA,
        name: 'マツダ',
        key: 'mazda',
        img: 'mazda.png',
      },
      {
        id: ID.SUBARU,
        name: 'スバル',
        key: 'subaru',
        img: 'subaru.png',
      },
      {
        id: ID.SUZUKI,
        name: 'スズキ',
        key: 'suzuki',
        img: 'suzuki.png',
      },
      {
        id: ID.DAIHATSU,
        name: 'ダイハツ',
        key: 'daihatsu',
        img: 'daihatsu.png',
      },
      {
        id: ID.MITSUBISHI,
        name: '三菱',
        key: 'mitsubishi',
        img: 'mitsubishi.png',
      },
    ];

    /**
     * 未選択データ、レクサスを除外したメーカーデータ
     */
    export const FILTERED_MAKER_DATA = MAKER.DATA.slice(2);

    /** 内部関数idでデータ探索
     * @param {MAKER.ID} id メーカーOD
     * @returns {number} index
     */
    function makerDataFind(id: MAKER.ID): number {
      const target = MAKER.DATA.findIndex((data) => data.id === id);
      if (target >= 0) {
        return target;
      }
      throw new Error('Invalid value');
    }

    /**
     * メーカー名
     * @export
     * @param {MAKER.ID} id
     * @returns {string}
     */
    export function NAME(id: MAKER.ID): string {
      return MAKER.DATA[makerDataFind(id)].name;
    }

    /** ID → KEY
     * @export
     * @param {MAKER.ID} id メーカID
     * @returns {string} KEY
     */
    export function ID_TO_KEY(id: MAKER.ID): string {
      return MAKER.DATA[makerDataFind(id)].key;
    }

    /** KEY → ID
     * @export
     * @param {string} key KEY
     * @returns {MAKER.ID} メーカID
     */
    export function KEY_TO_ID(key: string): MAKER.ID {
      const target = MAKER.DATA.find((data) => data.key === key);
      if (target) {
        return target.id;
      }
      throw new Error('Invalid value');
    }
  }

  /** ボディタイプ */
  export namespace BODY_TYPE {
    /**
     * ID
     * ※８は欠番。旧ハイブリッド
     * @export MINI_CAR:0,COMPACT_CAR:1,HATCH_BACK:2,MINIVAN:3,SUV:4,SEDAN:5,STATION_WAGON:6,COUPE:7
     * @enum {number}
     */

    // @export SEDAN:1,STATION_WAGON:2,MINIVAN:3,SUV:4,MINI_CAR:5,COMPACT_CAR:6,HATCH_BACK:7,COUPE:9
    // @enum {number}

    export enum ID {
      /** 未選択 */
      UNSELECTED = 0,
      /** セダン */
      SEDAN = 1,
      /** ステーションワゴン */
      STATION_WAGON = 2,
      /** ミニバン・ワゴン */
      MINIVAN = 3,
      /** SUV・クロカン */
      SUV = 4,
      /** 軽自動車 */
      MINI_CAR = 5,
      /** コンパクトカー */
      COMPACT_CAR = 6,
      /** ハッチバック */
      HATCH_BACK = 7,
      /** クーペ・オープンカー */
      COUPE = 9,
      /** その他 */
      OTHER = 10,
    }

    /** データ構造
     * 配列順序がリスト表示の順番に相当
     */
    export const DATA = [
      {
        id: ID.UNSELECTED,
        name: '',
        abb: '',
        key: '',
        isUnselected: true,
      },
      {
        id: ID.MINI_CAR,
        name: '軽自動車',
        abb: '軽自動車',
        key: 'mini',
        uri: '/lineup/mini/',
      },
      {
        id: ID.COMPACT_CAR,
        name: 'コンパクトカー',
        abb: 'コンパクト',
        key: 'compact',
        uri: '/lineup/compact/',
      },
      {
        id: ID.MINIVAN,
        name: 'ミニバン・ワゴン',
        abb: 'ミニバン',
        key: 'minivan',
        uri: '/lineup/minivan/',
      },
      {
        id: ID.SUV,
        name: 'SUV・クロカン',
        abb: 'SUV',
        key: 'suv',
        uri: '/lineup/suv/',
      },
      {
        id: ID.SEDAN,
        name: 'セダン',
        abb: 'セダン',
        key: 'sedan',
        uri: '/lineup/sedan/',
      },
      {
        id: ID.HATCH_BACK,
        name: 'ハッチバック',
        abb: '',
        key: 'hatchback',
        uri: '/lineup/hatchback/',
      },
      {
        id: ID.STATION_WAGON,
        name: 'ステーションワゴン',
        abb: '',
        key: 'stationwagon',
        uri: '/lineup/stationwagon/',
      },
      {
        id: ID.COUPE,
        name: 'クーペ・オープンカー',
        abb: 'クーペ',
        key: 'coupe',
        uri: '/lineup/coupe/',
      },
      {
        id: ID.OTHER,
        name: 'その他',
        abb: 'その他',
        key: 'other',
        uri: '/lineup/other/',
      },
    ];

    export const FILTERED_BODYTYPE_DATA = BODY_TYPE.DATA.slice(1, 9);

    /** 内部関数idでデータ探索
     * @param {BODY_TYPE.ID} id
     * @returns {number}
     */
    function bodytypeDataFind(id: BODY_TYPE.ID): number {
      const target = BODY_TYPE.DATA.findIndex((data) => data.id === id);
      if (target >= 0) {
        return target;
      }
      return BODY_TYPE.DATA.findIndex((data) => data.id === BODY_TYPE.ID.OTHER);
    }
    /**
     * メーカー名
     * @export
     * @param {BODY_TYPE.ID} id
     * @returns {string}
     */
    export function NAME(id: BODY_TYPE.ID): string {
      return BODY_TYPE.DATA[bodytypeDataFind(id)].name;
    }

    /**
     * ID → ABB
     * @export
     * @param {BODY_TYPE.ID} id
     * @returns {string}
     */
    export function ABB(id: BODY_TYPE.ID): string {
      return BODY_TYPE.DATA[bodytypeDataFind(id)].abb;
    }

    /** ID → KEY
     * @export
     * @param {BODY_TYPE.ID} id メーカID
     * @returns {string} KEY
     */
    export function ID_TO_KEY(id: BODY_TYPE.ID): string {
      return BODY_TYPE.DATA[bodytypeDataFind(id)].key;
    }

    /** KEY → ID
     * @export
     * @param {string} key KEY
     * @returns {BODY_TYPE.ID} メーカID
     */
    export function KEY_TO_ID(key: string): BODY_TYPE.ID {
      const target = BODY_TYPE.DATA.find((data) => data.key === key);
      if (target) {
        return target.id;
      }
      throw new Error('Invalid value');
    }
  }

  export namespace SELECTION {
    enum ID {
      SPACIOUS_COMPACT = '00010',
      COST_EFFICIENT = '00020',
      BEGINNER_FRIENDLY = '00030',
      COMMUTER_FRIENDLY = '00040',
      PET_FRIENDLY = '00050',
      FAMILY_FRIENDLY = '00060',
    }
    export const ITEMS = [
      {
        id: ID.SPACIOUS_COMPACT,
        condition: {
          body: [CARMO_CONST.BODY_TYPE.ID.MINI_CAR],
          option: [NEWCAR_CONST.SEARCH_OPTION.ID.HAS_POWER_SLIDE_DOOR],
        },
        benefit: {
          title: '広い軽自動車',
          description: '<strong>軽自動車の利点</strong>は多く、<strong>狭い道でもスムーズに通行</strong>できること、<strong>低燃費で経済的</strong>なこと、<strong>税金や保険料がお得</strong>なことなどが挙げられます。しかしながら、一般的な軽自動車では狭さや利便性に不満を感じる方も少なくありません。そこで注目したいのが、<strong>「電動スライドドア」を装備した広い軽自動車</strong>です。<br/>「電動スライドドア」のメリットは、狭い駐車スペースでもドアを開けやすいこと、子供や高齢者も扱いやすいこと、荷物を持っている際でもスムーズに乗り降りできることなどがあります。<strong>これに広い軽自動車の空間性</strong>を加えると、<strong>使い勝手と快適性が格段にアップ</strong>します。<br/>車探しで<strong>「広さ」</strong>と<strong>「使い勝手」</strong>を重視する方にとって、この組み合わせはまさに理想的。<strong>都市部での移動からアウトドアシーンまで幅広く対応</strong>します。<strong>狭い道や駐車場でもストレスフリーに過ごしたい方</strong>、新しい軽自動車の選択肢として、ぜひこの組み合わせを考慮してみてください。',
        },
        meta: {
          title: '車内が広くて安い軽自動車人気おすすめ厳選$${carsLength}$$選｜新車カーリース(車のサブスク)｜定額カルモくん',
          description: '軽自動車の新定番、電動スライドドア装備！狭い場所でもスムーズ、子供・高齢者も安心。広さと使い勝手を両立した新車の軽自動車をご紹介。都市の移動からアウトドアまで、あなたの新しいカーリース選びに最適。定額カルモくんで理想の一台を見つけよう！',
        },
      },
      {
        id: ID.COST_EFFICIENT,
        condition: {
          price: [NEWCAR_CONST.PRICE.ID.BW_10_20K, NEWCAR_CONST.PRICE.ID.BW_20_30K],
          body: [CARMO_CONST.BODY_TYPE.ID.MINI_CAR, CARMO_CONST.BODY_TYPE.ID.COMPACT_CAR, CARMO_CONST.BODY_TYPE.ID.MINIVAN],
          option: [NEWCAR_CONST.SEARCH_OPTION.ID.HAS_HYBRID],
        },
        benefit: {
          title: '維持費の安い',
          description: '<strong>維持費を抑えた車を選びたい</strong>と考える方に、月々の支払いが1万円代、2万円代と非常にリーズナブルな上、軽自動車やコンパクトカーの取り回しの良さ、ミニバンの収容力、そしてハイブリッド車の燃費の良さを兼ね備えている車が挙げられます｡<strong>維持費を重視するだけでなく、日常の使い勝手や環境への配慮</strong>も考える方､<strong>車探しに迷っている方</strong>、初めての車購入を考えている方、<strong>家計の助けとなる低維持費の車を求めている方</strong>などあなたのカーライフをより豊かに、そして経済的にサポートします。',
        },
        meta: {
          title: '維持費の安い新車人気おすすめ厳選$${carsLength}$$選｜新車カーリース(車のサブスク)｜定額カルモくん',
          description: '維持費が安いだけじゃない！月々の支払いがリーズナブルな新車をご紹介。軽自動車の取り回し、ミニバンの収容力、ハイブリッドの燃費効率...全部揃ってます。日常使いから環境への配慮、家計の助けまで。定額カルモくんで、あなたのカーライフを経済的に豊かにサポート！',
        },
      },
      {
        id: ID.BEGINNER_FRIENDLY,
        condition: {
          price: [NEWCAR_CONST.PRICE.ID.BW_10_20K],
          body: [CARMO_CONST.BODY_TYPE.ID.MINI_CAR, CARMO_CONST.BODY_TYPE.ID.COMPACT_CAR, CARMO_CONST.BODY_TYPE.ID.MINIVAN, CARMO_CONST.BODY_TYPE.ID.SUV],
        },
        benefit: {
          title: '初心者向け',
          description: '初めての車選びはドキドキのワクワクと、同時に<strong>「どれがいいのだろう」</strong>という迷いが交錯しますね。そんな<strong>あなたの迷いを解消</strong>すべく、初心者の方にぴったりの車を厳選してご紹介します。特に注目したいのは、<strong>11年カーリースで月額わずか1万円代という経済的な魅力</strong>。<br />そして、<strong>初心者でも扱いやすい軽自動車</strong>、<strong>都市部での移動に適したコンパクトカー</strong>、<strong>家族のお出かけに最適なミニバン</strong>、<strong>アウトドア派には欠かせないSUV</strong>と、様々なライフスタイルに合わせた選択が可能です。<br />車は単なる移動手段ではなく、日々の生活の大切なパートナー。初めての選択を心から後悔しないよう、<strong>信頼できる車をお求め安い価格でご提供</strong>することを心掛けています。初めての車選びでの不安や疑問、私たちがしっかりサポートします。<br />このオンラインサイトを<strong>あなたの新しいカーライフのスタート地点</strong>として、最適な選択の一助としていただければ幸いです。',
        },
        meta: {
          title: '初心者に人気おすすめの新車厳選$${carsLength}$$選｜新車カーリース(車のサブスク)｜定額カルモくん',
          description: '初めての車選び、迷いますよね？初心者にぴったりな新車をご紹介！月々1万円代でのカーリースや、初心者向け軽自動車からSUVまで、あらゆるライフスタイルに応えます。車はあなたの日常のパートナー。不安や疑問は私たちがサポート。定額カルモくんで、新しいカーライフのスタートを！',
        },
      },
      {
        id: ID.COMMUTER_FRIENDLY,
        condition: {
          price: [NEWCAR_CONST.PRICE.ID.BW_10_20K, NEWCAR_CONST.PRICE.ID.BW_20_30K],
          option: [NEWCAR_CONST.SEARCH_OPTION.ID.HAS_CRUISE_CTRL],
        },
        benefit: {
          title: '通勤・長距離',
          description: '<strong>通勤や長距離ドライブに頼りになる車</strong>をお探しのあなたのドライブをより快適にサポートする新車を取り揃えています。<br />特に、<strong>11年カーリースの月額1万円代から2万円代の経済的な範囲</strong>で、<strong>クルーズコントロール搭載</strong>の車をご紹介しております。<br /><strong>クルーズコントロールは、長距離運転の際の疲れを軽減</strong>させるため、非常に重宝する機能です。<br />定速運転をサポートすることで、<strong>ドライバーの疲労を減少</strong>させ、より<strong>安全な運転</strong>を実現します。<br />また、<strong>燃費の向上にも寄与</strong>するため、<strong>経済的な運転をサポート</strong>する点でも魅力的です。',
        },
        meta: {
          title: '通勤・長距離ドライブに人気おすすめの新車厳選$${carsLength}$$選｜新車カーリース(車のサブスク)｜定額カルモくん',
          description: '通勤や長距離の運転、もっと快適に。厳選された新車の中で、月額1万円代からクルーズコントロール搭載の新車を提供！長時間の運転でも疲れにくく、燃費向上もサポート。より安全で経済的なドライブを実現します。定額カルモくんで、あなたの理想のカーライフをサポート！',
        },
      },
      {
        id: ID.PET_FRIENDLY,
        condition: {
          price: [NEWCAR_CONST.PRICE.ID.BW_10_20K, NEWCAR_CONST.PRICE.ID.BW_20_30K],
          body: [CARMO_CONST.BODY_TYPE.ID.MINI_CAR, CARMO_CONST.BODY_TYPE.ID.COMPACT_CAR, CARMO_CONST.BODY_TYPE.ID.MINIVAN, CARMO_CONST.BODY_TYPE.ID.SUV],
          option: [NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SMART_KEY],
        },
        benefit: {
          title: 'ペット（犬）向け',
          description: '<strong>ペットや愛犬とのドライブ</strong>は、私たちの特別な時間となるものです。しかし、<strong>ペットを安心して乗せることができる車</strong>を見つけるのは簡単ではありません。あなたのペットや愛犬との最高のドライブをサポートする新車をご紹介します。<br />こちらの新車一覧では､<strong>11年カーリースで月額1万円代および2万円代という家計に優しい車</strong>を豊富に取り揃え､軽自動車からミニバン、コンパクトカー、そしてSUVまで、<strong>ペットとの移動に適した種類の車</strong>を幅広く選べます。<br />また、<strong>スマートキー搭載</strong>という便利機能も多数の車に取り入れられています。手が塞がった際にも、鍵を取り出さずにドアの開閉やエンジンの始動ができる<strong>スマートキーは、ペットのお世話をしながらの移動時</strong>にも大変役立ちます。',
        },
        meta: {
          title: 'ペット（犬）にやさしい人気おすすめの新車厳選$${carsLength}$$選｜新車カーリース(車のサブスク)｜定額カルモくん',
          description: 'ペットや愛犬とのドライブをもっと快適に。月額1万円代・2万円代でお求めやすい新車をご紹介。軽自動車からSUVまで、ペットの安全・快適さを考慮したモデルを幅広くラインナップ。さらに、ペットのお世話中でも便利なスマートキー搭載車も多数。定額カルモくんで、ペットとの特別なドライブをサポートします！',
        },
      },
      {
        id: ID.FAMILY_FRIENDLY,
        condition: {
          price: [NEWCAR_CONST.PRICE.ID.BW_10_20K, NEWCAR_CONST.PRICE.ID.BW_20_30K],
          option: [NEWCAR_CONST.SEARCH_OPTION.ID.HAS_POWER_SLIDE_DOOR],
        },
        benefit: {
          title: 'ファミリー向け',
          description: '<strong>新しい家族の仲間</strong>、そして<strong>新たなる生活の節目</strong>に、車選びは欠かせない大切な一つです。特に、<strong>お子様が生まれた際の車選び</strong>は、快適さはもちろん、<strong>安全性や機能性</strong>が求められます。そんなご家族のニーズに答える新車をご紹介しております。こちらの新車一覧では､<strong>11年カーリースで月額1万円代および2万円代というリーズナブルな価格帯</strong>で、お子様の成長を見守りながら長くお使いいただける車を厳選しています｡<br />そして、子育ての日常に役立つ<strong>電動スライドドア</strong>を搭載したモデルも多数取り揃えております｡<strong>お子様や荷物を乗せる際のストレスを軽減</strong>し、毎日の移動をより楽しく、より快適にサポートします。また、<strong>子供の成長とともに変わるライフスタイル</strong>に対応できるよう、<strong>多機能で使い勝手の良い内装や収納スペースを持つ車</strong>も多数ご用意しています。<strong>新しい家族のための新しい車</strong>、その選び方一つで日常がぐっと快適になります。',
        },
        meta: {
          title: 'ファミリーカー人気おすすめ厳選$${carsLength}$$選｜新車カーリース(車のサブスク)｜定額カルモくん',
          description: '新しい家族の節目に最適なファミリーカーをご紹介。月額1万円代・2万円代のリーズナブルな価格で、安全性・快適さを兼ね備えた車を厳選。電動スライドドア搭載のモデルや、子育ての日常をサポートする多機能内装を持つ車も。お子様の成長を見守る長く使える車で、毎日の移動をもっと快適に。定額カルモくんで家族の新たな一歩をサポートします！',
        },
      },
    ];
  }

  /** カルモマガジン */
  export namespace MAGAZINE {
    export const NO_POPUP_LOCAL_STORAGE_KEY = 'no_mag_popup';
  }

  /** 金額 */
  export namespace PRICE {
    export const TAX = 1.1;
  }

  export const copyright = '©2018- Nyle Inc.';

  /** 申込できる年齢 */
  export namespace AGE {
    export const OLDEST_AGE = 75;
    export const YOUNGEST_AGE = 18;
  }

  /** 資料請求 */
  export namespace REQUEST_INFORMATION {
    export const ALREADY_READ = 'is_already_read';
  }
}

export default CARMO_CONST;
