/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-namespace */
import CARMO_CONST from '@/common/util/const';

namespace Util {
  /**
   * Metaのtitle,descriptionの設定
   * @export
   * @param {string} title
   * @param {string} description
   */
  export function setMeta(title: string, description: string): void {
    if (title) {
      document.title = title;
    }
    const desc = document.querySelector("meta[name='description']");
    if (desc) {
      desc.setAttribute('content', description);
    }
  }

  /**
   * Metaのrobotsを設定
   */
  export function setRobots(): void {
    let meta = document.querySelector('meta[name=\'robots\']');
    if (!meta) {
      meta = document.createElement('meta');
      document.head.appendChild(meta);
      meta.setAttribute('name', 'robots');
    }
    meta.setAttribute('content', 'noindex');
  }
  /**
   * Metaのrobotsを削除
   */
  export function removeRobots(): void {
    const meta = document.querySelector('meta[name=\'robots\']');
    if (meta) {
      meta.remove();
    }
  }

  /**
   * OGP の設定
   *
   * @export
   * @param {{ property: string, content: string }} ogp
   */
  export function setOgp(ogp: { property: string, content: string }): void {
    let meta = document.querySelector(`meta[property='og:${ogp.property}']`);
    if (!meta) {
      meta = document.createElement('meta');
      document.head.appendChild(meta);
    }
    meta.setAttribute('property', `og:${ogp.property}`);
    meta.setAttribute('content', ogp.content);
  }

  /**
   * Twitter OGP の設定
   *
   * @export
   * @param {{ name: string, content: string }} ogp
   */
  export function setTwitterOgp(ogp: { name: string, content: string }): void {
    let meta = document.querySelector(`meta[name='twitter:${ogp.name}']`);
    if (!meta) {
      meta = document.createElement('meta');
      document.head.appendChild(meta);
    }
    meta.setAttribute('name', `twitter:${ogp.name}`);
    meta.setAttribute('content', ogp.content);
  }

  /**
   * カルモマガジンポップアップの非表示設定
   */
  export function setNoMagazinePopup(): void {
    if (window.localStorage) {
      try {
        // ポップアップ非表示フラグ格納
        window.localStorage.setItem(CARMO_CONST.MAGAZINE.NO_POPUP_LOCAL_STORAGE_KEY, 'true');
      } catch (e) {}
    }
  }

  /**
   * 消費税計算
   */
  export function calcTAX(arg?: number): number {
    const tax = CARMO_CONST.PRICE.TAX;
    if (arg) {
      return (arg < 0) ? Math.ceil(arg * tax) : Math.floor(arg * tax);
    }
    // undefined時は0
    return 0;
  }

  /**
   * 指定位置までスムーススクロール
   * @param id
   */
  export function scrollToId(id: string, margin?: number): void {
    // 画面の変更を考慮して気持ち遅らせる
    setTimeout(() => {
      const target: HTMLElement | null = document.getElementById(id);
      if (target) {
        window.scrollTo({
          top:
            window.scrollY + target.getBoundingClientRect().top - (margin || 0),
          behavior: 'smooth',
        });
      }
    }, 100);
  }

  export function setItem(key: string, value: object): void {
    try {
      if (window.localStorage) {
        window.localStorage.setItem(key, JSON.stringify(value));
      } else {
        document.cookie = `${key}=${JSON.stringify(value)};domain=${window.location.hostname};path=/;max-age=31536000`;
      }
    } catch (e) {}
  }

  export function getItem(key: string): string | undefined {
    try {
      let value;
      if (window.localStorage) {
        value = window.localStorage.getItem(key) || '';
      } else {
        const pattern = new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*=\\s*([^;]*).*$)|^.*$`);
        value = document.cookie.replace(pattern, '$1');
      }
      return JSON.parse(value);
    } catch (e) { return undefined; }
  }

  /**
   * contract/contact_infoを閲覧したことを記録する
   */
  export function setAlreadyReadContactInfo(): void {
    if (window.localStorage) {
      try {
        // 既読フラグ格納
        window.localStorage.setItem(
          CARMO_CONST.REQUEST_INFORMATION.ALREADY_READ,
          'true',
        );
        // urlリストの設定
        const localStorageUrlList = getItem('urlList') || '';
        let urlList = [];
        if (typeof localStorageUrlList === 'object') {
          urlList = localStorageUrlList;
        }
        if (localStorageUrlList[localStorageUrlList.length - 1] !== window.location.pathname
            && window.location.pathname === '/contract/contact_info/'
        ) {
          urlList.push(window.location.pathname);
        }
        if (urlList.length > 3) {
          urlList = urlList.slice(-3);
        }
        setItem('urlList', urlList);
      } catch (e) {}
    }
  }
}
export default Util;
