import API from '@/common/util/api';
import { store } from '@/common/core/store/decorator';
import { observable, action } from 'mobx';

export interface ILotteryManager {
  result: number | null;
  licenseNumber: string | null;
  lastName: string | null;
  reservationId: number | null;
}

type Result = ILotteryManager['result'];
type UserInfo = Pick<ILotteryManager, 'lastName' | 'licenseNumber' | 'reservationId'>;

@store({ persist: true, name: 'lotteryManager' })
export default class LotteryManagerStore {
  @observable result: number | null = null;

  @observable licenseNumber: string | null = '';

  @observable lastName: string | null = '';

  @observable reservationId: number | null = null;

  /**
   * くじ引き結果を格納する
   * @param result くじ引き結果
   */
  @action
  private setResult(result: Result): void {
    this.result = result;
  }

  /**
   * ユーザー情報を格納する
   * @param userInfo ユーザー情報
   */
  @action
  private setUserInfo(userInfo: UserInfo): void {
    Object.assign(this, userInfo);
  }

  /**
   * くじを引ける状態か？
   */
  get isDrawable(): boolean {
    // 免許証番号が格納されている状態で、くじ結果がまだない時に引くことができる
    return !!this.licenseNumber && this.result === null;
  }

  /**
   * くじ情報をリセットする
   * @param userInfo ユーザー情報
   */
  @action
  async resetLottery(userInfo: UserInfo): Promise<UserInfo> {
    // 免許証番号が前回と違えば、くじ結果をリセットする
    this.setResult(this.licenseNumber !== userInfo.licenseNumber ? null : this.result);

    // ユーザー情報返却
    this.setUserInfo(userInfo);
    return userInfo;
  }

  /**
   * くじを引く
   */
  @action
  async draw(): Promise<Result> {
    // くじ引きAPIを実行する
    const result = await API.post(API.EndPoints.DrawCampaign, {
      licenseNumber: this.licenseNumber,
      surName: this.lastName,
      reservationId: this.reservationId,
    });

    // console.log(result);
    // 結果が返ってこなければエラー
    if (!result) throw Error('Failed to get lottery result');

    // 引き結果を返却
    this.setResult(result.data.result);
    return result;
  }
}

export const lotteryManagerStore = new LotteryManagerStore();
