/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
import API from '@/common/util/api';
import URI from '@/newcar/util/uri';

/**
 * 商品情報API
 */
namespace ProductAPI {

  export async function getDigest(): Promise<any> {
    return API.get(API.API_SETTINGS.CDN_API.name, URI.APIEndPoints.Digest);
  }

  export async function getDetail(): Promise<any> {
    return API.get(API.API_SETTINGS.CDN_API.name, URI.APIEndPoints.Detail);
  }
}

export default ProductAPI;
