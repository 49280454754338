/* eslint-disable max-classes-per-file */
import CarDigest from '@/common/core/model/carDigest';
import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';
import { IMaintenancePlan } from '@/newcar/core/store/dataset/maintenance';
import NEWCAR_CONST from '@/newcar/util/const';

/**
 * ボディカラーの内訳
 */
export class BodyColorBreakdown {
  public id?: string;

  public name?: string;

  public leasingFee?: number;

  public supportFee?: number;
}

/**
 * オプションの内訳
 */
export class OptionBreakdown {
  public id?: string;

  public name?: string;

  public isStandard?: boolean;

  public leasingFee?: number;

  public supportFee?: number;
}

/**
 * もらえるオプション
 */
export class WantCarBreakdown {
  public price!: number;
}

/**
 * 審査申込内訳
 */
export class EntryBreakdown {
  /**
   * 賃貸期間（年）
   */
  public term?: NEWCAR_CONST.TERM.ID;

  /**
   * 車種
   */
  public car?: CarDigest;

  /**
   * グレード
   */
  public grade?: VehicleGrade;

  /**
   * ボディカラー
   */
  public bodyColor?: BodyColorBreakdown;

  /**
   * ボディタイプ
   */
  public bodyType?: string;

  /**
   * カーナビ
   */
  public carNavi?: OptionBreakdown;

  /**
   * ETC
   */
  public etc?: OptionBreakdown;

  /**
   * バックカメラ
   */
  public backCamera?: OptionBreakdown;

  /**
   * フロアマット
   */
  public floorMat?: OptionBreakdown;

  /**
   * 希望ナンバー
   */
  public wantNo?: OptionBreakdown;

  /**
   * ドアバイザー
   */
  public doorVisor?: OptionBreakdown;

  /**
   * メンテナンスプラン
   */
  public maintenancePlan?: IMaintenancePlan;

  /**
   * もらえるオプション
   */
  public wantCar?: WantCarBreakdown;

  /**
   * 備考
   */
  public memo?: string;

  /**
   * マスタ番号
   */
  public masterNumber?: string;

  /**
   * 流入元
   */
  public from?: string;

  /**
   * LPプラン名
   */
  public referrerPlan?: string;

  /**
   * LP月額
   */
  public referrerLpMonthlyPrice?: string;

  /**
   * LP流入元
   */
  public referrerLpLink?: string;

  /**
   * 流入元分類
   */
  public referrerType?: string;

  /**
   * 車種状態
   */
  public carCondition?: string;

  /**
   * 車種選択状況
   */
  public carSelectStatus?: string;

  /**
   * プラン詳細
   */
  public planDetails?: string;

  /**
   * CTA
   */
  public cta?: string;
}
