/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */
import CarDigest from '@/common/core/model/carDigest';
import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';
import CustomerSelection from '@/newcar/core/store/dataset/customerSelection';
import { IMaintenancePlan } from '@/newcar/core/store/dataset/maintenance';

/**
 * ボディカラーの内訳
 */
export class SelectBodyColor {
  public bodyColorId?: string;

  public bodyColorName?: string;

  public colorCode1?: string;

  public colorCode2?: string;

  public leasingFee?: number;

  public supportFee?: number;

  public isSelected = false;
}

/**
 * オプションの内訳
 */
export class SelectOption {
  public maintenanceId?: number;

  public id?: string;

  public name?: string;

  public isStandard?: boolean;

  public leasingFee?: number;

  public supportFee?: number;

  public price?: number; // メンテナンスプラン、もらえるオプションの値段

  public isSelected = false;
}

/**
 * 審査申込内訳
 */
export class SimulationResult {
  /**
   * 利用者の選択結果
   */
  public customerSelection: CustomerSelection | undefined;

  /**
   * 車種
   */
  public car: CarDigest | undefined;

  /**
   * グレード
   */
  public grade: VehicleGrade | undefined;

  /**
   * ボディカラー
   */
  public bodyColor: SelectBodyColor | undefined;

  /**
   * カーナビ
   */
  public carNavi?: SelectOption;

  /**
   * ETC
   */
  public etc?: SelectOption;

  /**
   * バックカメラ
   */
  public backCamera?: SelectOption;

  /**
   * フロアマット
   */
  public floorMat?: SelectOption;

  /**
   * 希望ナンバー
   */
  public wantNo?: SelectOption;

  /**
   * ドアバイザー
   */
  public doorVisor?: SelectOption;

  /**
   * メンテナンスプラン
   */
  public maintenancePlan?: IMaintenancePlan;

  /**
   * もらえるオプション
   */
  public wantCar?: SelectOption;

  /**
   * 総計
   */
  public totalPrice: number | undefined;

  /**
   * オプション
   */
  public optionstring: string | undefined

  /**
   *Creates an instance of SimulationResult.
   * @param {CustomerSelection} customerSelection
   * @param {CarDigest} carDigest
   * @param {VehicleGrade} grade
   * @param {number} totalPrice
   * @param {string} optionstring
   * @memberof SimulationResult
   */
  constructor(customerSelection: CustomerSelection | undefined, carDigest: CarDigest | undefined, grade: VehicleGrade | undefined, bodyColor: SelectBodyColor | undefined, totalPrice: number | undefined, optionstring: string | undefined) {
    this.customerSelection = customerSelection;
    this.car = carDigest;
    this.grade = grade;
    this.bodyColor = bodyColor;
    this.totalPrice = totalPrice;
    this.optionstring = optionstring;
  }

  get masterNumber(): string {
    let result = '';
    if (this.car) {
      result = this.car.masterNumber;
    }
    return result;
  }
}
