const array1 = [0x0000,
  0x0001,
  0x0002,
  0x0003,
  0x0004,
  0x0005,
  0x0006,
  0x0007,
  0x0008,
  0x0009,
  0x000A,
  0x000B,
  0x000C,
  0x000D,
  0x000E,
  0x000F,
  0x0010,
  0x0011,
  0x0012,
  0x0013,
  0x0014,
  0x0015,
  0x0016,
  0x0017,
  0x0018,
  0x0019,
  0x001A,
  0x001B,
  0x001C,
  0x001D,
  0x001E,
  0x001F,
  0x0020,
  0x0021,
  0x0022,
  0x0023,
  0x0024,
  0x0025,
  0x0026,
  0x0027,
  0x0028,
  0x0029,
  0x002A,
  0x002B,
  0x002C,
  0x002D,
  0x002E,
  0x002F,
  0x0030,
  0x0031,
  0x0032,
  0x0033,
  0x0034,
  0x0035,
  0x0036,
  0x0037,
  0x0038,
  0x0039,
  0x003A,
  0x003B,
  0x003C,
  0x003D,
  0x003E,
  0x003F,
  0x0040,
  0x0041,
  0x0042,
  0x0043,
  0x0044,
  0x0045,
  0x0046,
  0x0047,
  0x0048,
  0x0049,
  0x004A,
  0x004B,
  0x004C,
  0x004D,
  0x004E,
  0x004F,
  0x0050,
  0x0051,
  0x0052,
  0x0053,
  0x0054,
  0x0055,
  0x0056,
  0x0057,
  0x0058,
  0x0059,
  0x005A,
  0x005B,
  0x00A5,
  0x005D,
  0x005E,
  0x005F,
  0x0060,
  0x0061,
  0x0062,
  0x0063,
  0x0064,
  0x0065,
  0x0066,
  0x0067,
  0x0068,
  0x0069,
  0x006A,
  0x006B,
  0x006C,
  0x006D,
  0x006E,
  0x006F,
  0x0070,
  0x0071,
  0x0072,
  0x0073,
  0x0074,
  0x0075,
  0x0076,
  0x0077,
  0x0078,
  0x0079,
  0x007A,
  0x007B,
  0x007C,
  0x007D,
  0x203E,
  0x007F,
  0xFF61,
  0xFF62,
  0xFF63,
  0xFF64,
  0xFF65,
  0xFF66,
  0xFF67,
  0xFF68,
  0xFF69,
  0xFF6A,
  0xFF6B,
  0xFF6C,
  0xFF6D,
  0xFF6E,
  0xFF6F,
  0xFF70,
  0xFF71,
  0xFF72,
  0xFF73,
  0xFF74,
  0xFF75,
  0xFF76,
  0xFF77,
  0xFF78,
  0xFF79,
  0xFF7A,
  0xFF7B,
  0xFF7C,
  0xFF7D,
  0xFF7E,
  0xFF7F,
  0xFF80,
  0xFF81,
  0xFF82,
  0xFF83,
  0xFF84,
  0xFF85,
  0xFF86,
  0xFF87,
  0xFF88,
  0xFF89,
  0xFF8A,
  0xFF8B,
  0xFF8C,
  0xFF8D,
  0xFF8E,
  0xFF8F,
  0xFF90,
  0xFF91,
  0xFF92,
  0xFF93,
  0xFF94,
  0xFF95,
  0xFF96,
  0xFF97,
  0xFF98,
  0xFF99,
  0xFF9A,
  0xFF9B,
  0xFF9C,
  0xFF9D,
  0xFF9E,
  0xFF9F,
  0x3000,
  0x3001,
  0x3002,
  0xFF0C,
  0xFF0E,
  0x30FB,
  0xFF1A,
  0xFF1B,
  0xFF1F,
  0xFF01,
  0x309B,
  0x309C,
  0x00B4,
  0xFF40,
  0x00A8,
  0xFF3E,
  0xFFE3,
  0xFF3F,
  0x30FD,
  0x30FE,
  0x309D,
  0x309E,
  0x3003,
  0x4EDD,
  0x3005,
  0x3006,
  0x3007,
  0x30FC,
  0x2014,
  0x2010,
  0xFF0F,
  0x005C,
  0x301C,
  0x2016,
  0xFF5C,
  0x2026,
  0x2025,
  0x2018,
  0x2019,
  0x201C,
  0x201D,
  0xFF08,
  0xFF09,
  0x3014,
  0x3015,
  0xFF3B,
  0xFF3D,
  0xFF5B,
  0xFF5D,
  0x3008,
  0x3009,
  0x300A,
  0x300B,
  0x300C,
  0x300D,
  0x300E,
  0x300F,
  0x3010,
  0x3011,
  0xFF0B,
  0x2212,
  0x00B1,
  0x00D7,
  0x00F7,
  0xFF1D,
  0x2260,
  0xFF1C,
  0xFF1E,
  0x2266,
  0x2267,
  0x221E,
  0x2234,
  0x2642,
  0x2640,
  0x00B0,
  0x2032,
  0x2033,
  0x2103,
  0xFFE5,
  0xFF04,
  0x00A2,
  0x00A3,
  0xFF05,
  0xFF03,
  0xFF06,
  0xFF0A,
  0xFF20,
  0x00A7,
  0x2606,
  0x2605,
  0x25CB,
  0x25CF,
  0x25CE,
  0x25C7,
  0x25C6,
  0x25A1,
  0x25A0,
  0x25B3,
  0x25B2,
  0x25BD,
  0x25BC,
  0x203B,
  0x3012,
  0x2192,
  0x2190,
  0x2191,
  0x2193,
  0x3013,
  0xFF07,
  0xFF02,
  0xFF0D,
  0x007E,
  0x3033,
  0x3034,
  0x3035,
  0x303B,
  0x303C,
  0x30FF,
  0x309F,
  0x2208,
  0x220B,
  0x2286,
  0x2287,
  0x2282,
  0x2283,
  0x222A,
  0x2229,
  0x2284,
  0x2285,
  0x228A,
  0x228B,
  0x2209,
  0x2205,
  0x2305,
  0x2306,
  0x2227,
  0x2228,
  0x00AC,
  0x21D2,
  0x21D4,
  0x2200,
  0x2203,
  0x2295,
  0x2296,
  0x2297,
  0x2225,
  0x2226,
  0xFF5F,
  0xFF60,
  0x3018,
  0x3019,
  0x3016,
  0x3017,
  0x2220,
  0x22A5,
  0x2312,
  0x2202,
  0x2207,
  0x2261,
  0x2252,
  0x226A,
  0x226B,
  0x221A,
  0x223D,
  0x221D,
  0x2235,
  0x222B,
  0x222C,
  0x2262,
  0x2243,
  0x2245,
  0x2248,
  0x2276,
  0x2277,
  0x2194,
  0x212B,
  0x2030,
  0x266F,
  0x266D,
  0x266A,
  0x2020,
  0x2021,
  0x00B6,
  0x266E,
  0x266B,
  0x266C,
  0x2669,
  0x25EF,
  0x25B7,
  0x25B6,
  0x25C1,
  0x25C0,
  0x2197,
  0x2198,
  0x2196,
  0x2199,
  0x21C4,
  0x21E8,
  0x21E6,
  0x21E7,
  0x21E9,
  0x2934,
  0x2935,
  0xFF10,
  0xFF11,
  0xFF12,
  0xFF13,
  0xFF14,
  0xFF15,
  0xFF16,
  0xFF17,
  0xFF18,
  0xFF19,
  0x29BF,
  0x25C9,
  0x303D,
  0xFE46,
  0xFE45,
  0x25E6,
  0x2022,
  0xFF21,
  0xFF22,
  0xFF23,
  0xFF24,
  0xFF25,
  0xFF26,
  0xFF27,
  0xFF28,
  0xFF29,
  0xFF2A,
  0xFF2B,
  0xFF2C,
  0xFF2D,
  0xFF2E,
  0xFF2F,
  0xFF30,
  0xFF31,
  0xFF32,
  0xFF33,
  0xFF34,
  0xFF35,
  0xFF36,
  0xFF37,
  0xFF38,
  0xFF39,
  0xFF3A,
  0x2213,
  0x2135,
  0x210F,
  0x33CB,
  0x2113,
  0x2127,
  0xFF41,
  0xFF42,
  0xFF43,
  0xFF44,
  0xFF45,
  0xFF46,
  0xFF47,
  0xFF48,
  0xFF49,
  0xFF4A,
  0xFF4B,
  0xFF4C,
  0xFF4D,
  0xFF4E,
  0xFF4F,
  0xFF50,
  0xFF51,
  0xFF52,
  0xFF53,
  0xFF54,
  0xFF55,
  0xFF56,
  0xFF57,
  0xFF58,
  0xFF59,
  0xFF5A,
  0x30A0,
  0x2013,
  0x29FA,
  0x29FB,
  0x3041,
  0x3042,
  0x3043,
  0x3044,
  0x3045,
  0x3046,
  0x3047,
  0x3048,
  0x3049,
  0x304A,
  0x304B,
  0x304C,
  0x304D,
  0x304E,
  0x304F,
  0x3050,
  0x3051,
  0x3052,
  0x3053,
  0x3054,
  0x3055,
  0x3056,
  0x3057,
  0x3058,
  0x3059,
  0x305A,
  0x305B,
  0x305C,
  0x305D,
  0x305E,
  0x305F,
  0x3060,
  0x3061,
  0x3062,
  0x3063,
  0x3064,
  0x3065,
  0x3066,
  0x3067,
  0x3068,
  0x3069,
  0x306A,
  0x306B,
  0x306C,
  0x306D,
  0x306E,
  0x306F,
  0x3070,
  0x3071,
  0x3072,
  0x3073,
  0x3074,
  0x3075,
  0x3076,
  0x3077,
  0x3078,
  0x3079,
  0x307A,
  0x307B,
  0x307C,
  0x307D,
  0x307E,
  0x307F,
  0x3080,
  0x3081,
  0x3082,
  0x3083,
  0x3084,
  0x3085,
  0x3086,
  0x3087,
  0x3088,
  0x3089,
  0x308A,
  0x308B,
  0x308C,
  0x308D,
  0x308E,
  0x308F,
  0x3090,
  0x3091,
  0x3092,
  0x3093,
  0x3094,
  0x3095,
  0x3096,
  0x30A1,
  0x30A2,
  0x30A3,
  0x30A4,
  0x30A5,
  0x30A6,
  0x30A7,
  0x30A8,
  0x30A9,
  0x30AA,
  0x30AB,
  0x30AC,
  0x30AD,
  0x30AE,
  0x30AF,
  0x30B0,
  0x30B1,
  0x30B2,
  0x30B3,
  0x30B4,
  0x30B5,
  0x30B6,
  0x30B7,
  0x30B8,
  0x30B9,
  0x30BA,
  0x30BB,
  0x30BC,
  0x30BD,
  0x30BE,
  0x30BF,
  0x30C0,
  0x30C1,
  0x30C2,
  0x30C3,
  0x30C4,
  0x30C5,
  0x30C6,
  0x30C7,
  0x30C8,
  0x30C9,
  0x30CA,
  0x30CB,
  0x30CC,
  0x30CD,
  0x30CE,
  0x30CF,
  0x30D0,
  0x30D1,
  0x30D2,
  0x30D3,
  0x30D4,
  0x30D5,
  0x30D6,
  0x30D7,
  0x30D8,
  0x30D9,
  0x30DA,
  0x30DB,
  0x30DC,
  0x30DD,
  0x30DE,
  0x30DF,
  0x30E0,
  0x30E1,
  0x30E2,
  0x30E3,
  0x30E4,
  0x30E5,
  0x30E6,
  0x30E7,
  0x30E8,
  0x30E9,
  0x30EA,
  0x30EB,
  0x30EC,
  0x30ED,
  0x30EE,
  0x30EF,
  0x30F0,
  0x30F1,
  0x30F2,
  0x30F3,
  0x30F4,
  0x30F5,
  0x30F6,
  0x0391,
  0x0392,
  0x0393,
  0x0394,
  0x0395,
  0x0396,
  0x0397,
  0x0398,
  0x0399,
  0x039A,
  0x039B,
  0x039C,
  0x039D,
  0x039E,
  0x039F,
  0x03A0,
  0x03A1,
  0x03A3,
  0x03A4,
  0x03A5,
  0x03A6,
  0x03A7,
  0x03A8,
  0x03A9,
  0x2664,
  0x2660,
  0x2662,
  0x2666,
  0x2661,
  0x2665,
  0x2667,
  0x2663,
  0x03B1,
  0x03B2,
  0x03B3,
  0x03B4,
  0x03B5,
  0x03B6,
  0x03B7,
  0x03B8,
  0x03B9,
  0x03BA,
  0x03BB,
  0x03BC,
  0x03BD,
  0x03BE,
  0x03BF,
  0x03C0,
  0x03C1,
  0x03C3,
  0x03C4,
  0x03C5,
  0x03C6,
  0x03C7,
  0x03C8,
  0x03C9,
  0x03C2,
  0x24F5,
  0x24F6,
  0x24F7,
  0x24F8,
  0x24F9,
  0x24FA,
  0x24FB,
  0x24FC,
  0x24FD,
  0x24FE,
  0x2616,
  0x2617,
  0x3020,
  0x260E,
  0x2600,
  0x2601,
  0x2602,
  0x2603,
  0x2668,
  0x25B1,
  0x31F0,
  0x31F1,
  0x31F2,
  0x31F3,
  0x31F4,
  0x31F5,
  0x31F6,
  0x31F7,
  0x31F8,
  0x31F9,
  0x31FA,
  0x31FB,
  0x31FC,
  0x31FD,
  0x31FE,
  0x31FF,
  0x0410,
  0x0411,
  0x0412,
  0x0413,
  0x0414,
  0x0415,
  0x0401,
  0x0416,
  0x0417,
  0x0418,
  0x0419,
  0x041A,
  0x041B,
  0x041C,
  0x041D,
  0x041E,
  0x041F,
  0x0420,
  0x0421,
  0x0422,
  0x0423,
  0x0424,
  0x0425,
  0x0426,
  0x0427,
  0x0428,
  0x0429,
  0x042A,
  0x042B,
  0x042C,
  0x042D,
  0x042E,
  0x042F,
  0x23BE,
  0x23BF,
  0x23C0,
  0x23C1,
  0x23C2,
  0x23C3,
  0x23C4,
  0x23C5,
  0x23C6,
  0x23C7,
  0x23C8,
  0x23C9,
  0x23CA,
  0x23CB,
  0x23CC,
  0x0430,
  0x0431,
  0x0432,
  0x0433,
  0x0434,
  0x0435,
  0x0451,
  0x0436,
  0x0437,
  0x0438,
  0x0439,
  0x043A,
  0x043B,
  0x043C,
  0x043D,
  0x043E,
  0x043F,
  0x0440,
  0x0441,
  0x0442,
  0x0443,
  0x0444,
  0x0445,
  0x0446,
  0x0447,
  0x0448,
  0x0449,
  0x044A,
  0x044B,
  0x044C,
  0x044D,
  0x044E,
  0x044F,
  0x30F7,
  0x30F8,
  0x30F9,
  0x30FA,
  0x22DA,
  0x22DB,
  0x2153,
  0x2154,
  0x2155,
  0x2713,
  0x2318,
  0x2423,
  0x23CE,
  0x2500,
  0x2502,
  0x250C,
  0x2510,
  0x2518,
  0x2514,
  0x251C,
  0x252C,
  0x2524,
  0x2534,
  0x253C,
  0x2501,
  0x2503,
  0x250F,
  0x2513,
  0x251B,
  0x2517,
  0x2523,
  0x2533,
  0x252B,
  0x253B,
  0x254B,
  0x2520,
  0x252F,
  0x2528,
  0x2537,
  0x253F,
  0x251D,
  0x2530,
  0x2525,
  0x2538,
  0x2542,
  0x3251,
  0x3252,
  0x3253,
  0x3254,
  0x3255,
  0x3256,
  0x3257,
  0x3258,
  0x3259,
  0x325A,
  0x325B,
  0x325C,
  0x325D,
  0x325E,
  0x325F,
  0x32B1,
  0x32B2,
  0x32B3,
  0x32B4,
  0x32B5,
  0x32B6,
  0x32B7,
  0x32B8,
  0x32B9,
  0x32BA,
  0x32BB,
  0x32BC,
  0x32BD,
  0x32BE,
  0x32BF,
  0x25D0,
  0x25D1,
  0x25D2,
  0x25D3,
  0x203C,
  0x2047,
  0x2048,
  0x2049,
  0x01CD,
  0x01CE,
  0x01D0,
  0x1E3E,
  0x1E3F,
  0x01F8,
  0x01F9,
  0x01D1,
  0x01D2,
  0x01D4,
  0x01D6,
  0x01D8,
  0x01DA,
  0x01DC,
  0x20AC,
  0x00A0,
  0x00A1,
  0x00A4,
  0x00A6,
  0x00A9,
  0x00AA,
  0x00AB,
  0x00AD,
  0x00AE,
  0x00AF,
  0x00B2,
  0x00B3,
  0x00B7,
  0x00B8,
  0x00B9,
  0x00BA,
  0x00BB,
  0x00BC,
  0x00BD,
  0x00BE,
  0x00BF,
  0x00C0,
  0x00C1,
  0x00C2,
  0x00C3,
  0x00C4,
  0x00C5,
  0x00C6,
  0x00C7,
  0x00C8,
  0x00C9,
  0x00CA,
  0x00CB,
  0x00CC,
  0x00CD,
  0x00CE,
  0x00CF,
  0x00D0,
  0x00D1,
  0x00D2,
  0x00D3,
  0x00D4,
  0x00D5,
  0x00D6,
  0x00D8,
  0x00D9,
  0x00DA,
  0x00DB,
  0x00DC,
  0x00DD,
  0x00DE,
  0x00DF,
  0x00E0,
  0x00E1,
  0x00E2,
  0x00E3,
  0x00E4,
  0x00E5,
  0x00E6,
  0x00E7,
  0x00E8,
  0x00E9,
  0x00EA,
  0x00EB,
  0x00EC,
  0x00ED,
  0x00EE,
  0x00EF,
  0x00F0,
  0x00F1,
  0x00F2,
  0x00F3,
  0x00F4,
  0x00F5,
  0x00F6,
  0x00F8,
  0x00F9,
  0x00FA,
  0x00FB,
  0x00FC,
  0x00FD,
  0x00FE,
  0x00FF,
  0x0100,
  0x012A,
  0x016A,
  0x0112,
  0x014C,
  0x0101,
  0x012B,
  0x016B,
  0x0113,
  0x014D,
  0x0104,
  0x02D8,
  0x0141,
  0x013D,
  0x015A,
  0x0160,
  0x015E,
  0x0164,
  0x0179,
  0x017D,
  0x017B,
  0x0105,
  0x02DB,
  0x0142,
  0x013E,
  0x015B,
  0x02C7,
  0x0161,
  0x015F,
  0x0165,
  0x017A,
  0x02DD,
  0x017E,
  0x017C,
  0x0154,
  0x0102,
  0x0139,
  0x0106,
  0x010C,
  0x0118,
  0x011A,
  0x010E,
  0x0143,
  0x0147,
  0x0150,
  0x0158,
  0x016E,
  0x0170,
  0x0162,
  0x0155,
  0x0103,
  0x013A,
  0x0107,
  0x010D,
  0x0119,
  0x011B,
  0x010F,
  0x0111,
  0x0144,
  0x0148,
  0x0151,
  0x0159,
  0x016F,
  0x0171,
  0x0163,
  0x02D9,
  0x0108,
  0x011C,
  0x0124,
  0x0134,
  0x015C,
  0x016C,
  0x0109,
  0x011D,
  0x0125,
  0x0135,
  0x015D,
  0x016D,
  0x0271,
  0x028B,
  0x027E,
  0x0283,
  0x0292,
  0x026C,
  0x026E,
  0x0279,
  0x0288,
  0x0256,
  0x0273,
  0x027D,
  0x0282,
  0x0290,
  0x027B,
  0x026D,
  0x025F,
  0x0272,
  0x029D,
  0x028E,
  0x0261,
  0x014B,
  0x0270,
  0x0281,
  0x0127,
  0x0295,
  0x0294,
  0x0266,
  0x0298,
  0x01C2,
  0x0253,
  0x0257,
  0x0284,
  0x0260,
  0x0193,
  0x0153,
  0x0152,
  0x0268,
  0x0289,
  0x0258,
  0x0275,
  0x0259,
  0x025C,
  0x025E,
  0x0250,
  0x026F,
  0x028A,
  0x0264,
  0x028C,
  0x0254,
  0x0251,
  0x0252,
  0x028D,
  0x0265,
  0x02A2,
  0x02A1,
  0x0255,
  0x0291,
  0x027A,
  0x0267,
  0x025A,
  0x01FD,
  0x1F70,
  0x1F71,
  0x1F72,
  0x1F73,
  0x0361,
  0x02C8,
  0x02CC,
  0x02D0,
  0x02D1,
  0x0306,
  0x203F,
  0x030B,
  0x0301,
  0x0304,
  0x0300,
  0x030F,
  0x030C,
  0x0302,
  0x02E5,
  0x02E6,
  0x02E7,
  0x02E8,
  0x02E9,
  0x0325,
  0x032C,
  0x0339,
  0x031C,
  0x031F,
  0x0320,
  0x0308,
  0x033D,
  0x0329,
  0x032F,
  0x02DE,
  0x0324,
  0x0330,
  0x033C,
  0x0334,
  0x031D,
  0x031E,
  0x0318,
  0x0319,
  0x032A,
  0x033A,
  0x033B,
  0x0303,
  0x031A,
  0x2776,
  0x2777,
  0x2778,
  0x2779,
  0x277A,
  0x277B,
  0x277C,
  0x277D,
  0x277E,
  0x277F,
  0x24EB,
  0x24EC,
  0x24ED,
  0x24EE,
  0x24EF,
  0x24F0,
  0x24F1,
  0x24F2,
  0x24F3,
  0x24F4,
  0x2170,
  0x2171,
  0x2172,
  0x2173,
  0x2174,
  0x2175,
  0x2176,
  0x2177,
  0x2178,
  0x2179,
  0x217A,
  0x217B,
  0x24D0,
  0x24D1,
  0x24D2,
  0x24D3,
  0x24D4,
  0x24D5,
  0x24D6,
  0x24D7,
  0x24D8,
  0x24D9,
  0x24DA,
  0x24DB,
  0x24DC,
  0x24DD,
  0x24DE,
  0x24DF,
  0x24E0,
  0x24E1,
  0x24E2,
  0x24E3,
  0x24E4,
  0x24E5,
  0x24E6,
  0x24E7,
  0x24E8,
  0x24E9,
  0x32D0,
  0x32D1,
  0x32D2,
  0x32D3,
  0x32D4,
  0x32D5,
  0x32D6,
  0x32D7,
  0x32D8,
  0x32D9,
  0x32DA,
  0x32DB,
  0x32DC,
  0x32DD,
  0x32DE,
  0x32DF,
  0x32E0,
  0x32E1,
  0x32E2,
  0x32E3,
  0x32FA,
  0x32E9,
  0x32E5,
  0x32ED,
  0x32EC,
  0x2051,
  0x2042,
  0x2460,
  0x2461,
  0x2462,
  0x2463,
  0x2464,
  0x2465,
  0x2466,
  0x2467,
  0x2468,
  0x2469,
  0x246A,
  0x246B,
  0x246C,
  0x246D,
  0x246E,
  0x246F,
  0x2470,
  0x2471,
  0x2472,
  0x2473,
  0x2160,
  0x2161,
  0x2162,
  0x2163,
  0x2164,
  0x2165,
  0x2166,
  0x2167,
  0x2168,
  0x2169,
  0x216A,
  0x3349,
  0x3314,
  0x3322,
  0x334D,
  0x3318,
  0x3327,
  0x3303,
  0x3336,
  0x3351,
  0x3357,
  0x330D,
  0x3326,
  0x3323,
  0x332B,
  0x334A,
  0x333B,
  0x339C,
  0x339D,
  0x339E,
  0x338E,
  0x338F,
  0x33C4,
  0x33A1,
  0x216B,
  0x337B,
  0x301D,
  0x301F,
  0x2116,
  0x33CD,
  0x2121,
  0x32A4,
  0x32A5,
  0x32A6,
  0x32A7,
  0x32A8,
  0x3231,
  0x3232,
  0x3239,
  0x337E,
  0x337D,
  0x337C,
  0x222E,
  0x221F,
  0x22BF,
  0x2756,
  0x261E,
  0x4FF1,
  0x2000B,
  0x3402,
  0x4E28,
  0x4E2F,
  0x4E30,
  0x4E8D,
  0x4EE1,
  0x4EFD,
  0x4EFF,
  0x4F03,
  0x4F0B,
  0x4F60,
  0x4F48,
  0x4F49,
  0x4F56,
  0x4F5F,
  0x4F6A,
  0x4F6C,
  0x4F7E,
  0x4F8A,
  0x4F94,
  0x4F97,
  0xFA30,
  0x4FC9,
  0x4FE0,
  0x5001,
  0x5002,
  0x500E,
  0x5018,
  0x5027,
  0x502E,
  0x5040,
  0x503B,
  0x5041,
  0x5094,
  0x50CC,
  0x50F2,
  0x50D0,
  0x50E6,
  0xFA31,
  0x5106,
  0x5103,
  0x510B,
  0x511E,
  0x5135,
  0x514A,
  0xFA32,
  0x5155,
  0x5157,
  0x34B5,
  0x519D,
  0x51C3,
  0x51CA,
  0x51DE,
  0x51E2,
  0x51EE,
  0x5201,
  0x34DB,
  0x5213,
  0x5215,
  0x5249,
  0x5257,
  0x5261,
  0x5293,
  0x52C8,
  0xFA33,
  0x52CC,
  0x52D0,
  0x52D6,
  0x52DB,
  0xFA34,
  0x52F0,
  0x52FB,
  0x5300,
  0x5307,
  0x531C,
  0xFA35,
  0x5361,
  0x5363,
  0x537D,
  0x5393,
  0x539D,
  0x53B2,
  0x5412,
  0x5427,
  0x544D,
  0x549C,
  0x546B,
  0x5474,
  0x547F,
  0x5488,
  0x5496,
  0x54A1,
  0x54A9,
  0x54C6,
  0x54FF,
  0x550E,
  0x552B,
  0x5535,
  0x5550,
  0x555E,
  0x5581,
  0x5586,
  0x558E,
  0xFA36,
  0x55AD,
  0x55CE,
  0xFA37,
  0x5608,
  0x560E,
  0x563B,
  0x5649,
  0x5676,
  0x5666,
  0xFA38,
  0x566F,
  0x5671,
  0x5672,
  0x5699,
  0x569E,
  0x56A9,
  0x56AC,
  0x56B3,
  0x56C9,
  0x56CA,
  0x570A,
  0x2123D,
  0x5721,
  0x572F,
  0x5733,
  0x5734,
  0x5770,
  0x5777,
  0x577C,
  0x579C,
  0xFA0F,
  0x2131B,
  0x57B8,
  0x57C7,
  0x57C8,
  0x57CF,
  0x57E4,
  0x57ED,
  0x57F5,
  0x57F6,
  0x57FF,
  0x5809,
  0xFA10,
  0x5861,
  0x5864,
  0xFA39,
  0x587C,
  0x5889,
  0x589E,
  0xFA3A,
  0x58A9,
  0x2146E,
  0x58D2,
  0x58CE,
  0x58D4,
  0x58DA,
  0x58E0,
  0x58E9,
  0x590C,
  0x8641,
  0x595D,
  0x596D,
  0x598B,
  0x5992,
  0x59A4,
  0x59C3,
  0x59D2,
  0x59DD,
  0x5A13,
  0x5A23,
  0x5A67,
  0x5A6D,
  0x5A77,
  0x5A7E,
  0x5A84,
  0x5A9E,
  0x5AA7,
  0x5AC4,
  0x218BD,
  0x5B19,
  0x5B25,
  0x525D,
  0x4E9C,
  0x5516,
  0x5A03,
  0x963F,
  0x54C0,
  0x611B,
  0x6328,
  0x59F6,
  0x9022,
  0x8475,
  0x831C,
  0x7A50,
  0x60AA,
  0x63E1,
  0x6E25,
  0x65ED,
  0x8466,
  0x82A6,
  0x9BF5,
  0x6893,
  0x5727,
  0x65A1,
  0x6271,
  0x5B9B,
  0x59D0,
  0x867B,
  0x98F4,
  0x7D62,
  0x7DBE,
  0x9B8E,
  0x6216,
  0x7C9F,
  0x88B7,
  0x5B89,
  0x5EB5,
  0x6309,
  0x6697,
  0x6848,
  0x95C7,
  0x978D,
  0x674F,
  0x4EE5,
  0x4F0A,
  0x4F4D,
  0x4F9D,
  0x5049,
  0x56F2,
  0x5937,
  0x59D4,
  0x5A01,
  0x5C09,
  0x60DF,
  0x610F,
  0x6170,
  0x6613,
  0x6905,
  0x70BA,
  0x754F,
  0x7570,
  0x79FB,
  0x7DAD,
  0x7DEF,
  0x80C3,
  0x840E,
  0x8863,
  0x8B02,
  0x9055,
  0x907A,
  0x533B,
  0x4E95,
  0x4EA5,
  0x57DF,
  0x80B2,
  0x90C1,
  0x78EF,
  0x4E00,
  0x58F1,
  0x6EA2,
  0x9038,
  0x7A32,
  0x8328,
  0x828B,
  0x9C2F,
  0x5141,
  0x5370,
  0x54BD,
  0x54E1,
  0x56E0,
  0x59FB,
  0x5F15,
  0x98F2,
  0x6DEB,
  0x80E4,
  0x852D,
  0x9662,
  0x9670,
  0x96A0,
  0x97FB,
  0x540B,
  0x53F3,
  0x5B87,
  0x70CF,
  0x7FBD,
  0x8FC2,
  0x96E8,
  0x536F,
  0x9D5C,
  0x7ABA,
  0x4E11,
  0x7893,
  0x81FC,
  0x6E26,
  0x5618,
  0x5504,
  0x6B1D,
  0x851A,
  0x9C3B,
  0x59E5,
  0x53A9,
  0x6D66,
  0x74DC,
  0x958F,
  0x5642,
  0x4E91,
  0x904B,
  0x96F2,
  0x834F,
  0x990C,
  0x53E1,
  0x55B6,
  0x5B30,
  0x5F71,
  0x6620,
  0x66F3,
  0x6804,
  0x6C38,
  0x6CF3,
  0x6D29,
  0x745B,
  0x76C8,
  0x7A4E,
  0x9834,
  0x82F1,
  0x885B,
  0x8A60,
  0x92ED,
  0x6DB2,
  0x75AB,
  0x76CA,
  0x99C5,
  0x60A6,
  0x8B01,
  0x8D8A,
  0x95B2,
  0x698E,
  0x53AD,
  0x5186,
  0x5712,
  0x5830,
  0x5944,
  0x5BB4,
  0x5EF6,
  0x6028,
  0x63A9,
  0x63F4,
  0x6CBF,
  0x6F14,
  0x708E,
  0x7114,
  0x7159,
  0x71D5,
  0x733F,
  0x7E01,
  0x8276,
  0x82D1,
  0x8597,
  0x9060,
  0x925B,
  0x9D1B,
  0x5869,
  0x65BC,
  0x6C5A,
  0x7525,
  0x51F9,
  0x592E,
  0x5965,
  0x5F80,
  0x5FDC,
  0x62BC,
  0x65FA,
  0x6A2A,
  0x6B27,
  0x6BB4,
  0x738B,
  0x7FC1,
  0x8956,
  0x9D2C,
  0x9D0E,
  0x9EC4,
  0x5CA1,
  0x6C96,
  0x837B,
  0x5104,
  0x5C4B,
  0x61B6,
  0x81C6,
  0x6876,
  0x7261,
  0x4E59,
  0x4FFA,
  0x5378,
  0x6069,
  0x6E29,
  0x7A4F,
  0x97F3,
  0x4E0B,
  0x5316,
  0x4EEE,
  0x4F55,
  0x4F3D,
  0x4FA1,
  0x4F73,
  0x52A0,
  0x53EF,
  0x5609,
  0x590F,
  0x5AC1,
  0x5BB6,
  0x5BE1,
  0x79D1,
  0x6687,
  0x679C,
  0x67B6,
  0x6B4C,
  0x6CB3,
  0x706B,
  0x73C2,
  0x798D,
  0x79BE,
  0x7A3C,
  0x7B87,
  0x82B1,
  0x82DB,
  0x8304,
  0x8377,
  0x83EF,
  0x83D3,
  0x8766,
  0x8AB2,
  0x5629,
  0x8CA8,
  0x8FE6,
  0x904E,
  0x971E,
  0x868A,
  0x4FC4,
  0x5CE8,
  0x6211,
  0x7259,
  0x753B,
  0x81E5,
  0x82BD,
  0x86FE,
  0x8CC0,
  0x96C5,
  0x9913,
  0x99D5,
  0x4ECB,
  0x4F1A,
  0x89E3,
  0x56DE,
  0x584A,
  0x58CA,
  0x5EFB,
  0x5FEB,
  0x602A,
  0x6094,
  0x6062,
  0x61D0,
  0x6212,
  0x62D0,
  0x6539,
  0x9B41,
  0x6666,
  0x68B0,
  0x6D77,
  0x7070,
  0x754C,
  0x7686,
  0x7D75,
  0x82A5,
  0x87F9,
  0x958B,
  0x968E,
  0x8C9D,
  0x51F1,
  0x52BE,
  0x5916,
  0x54B3,
  0x5BB3,
  0x5D16,
  0x6168,
  0x6982,
  0x6DAF,
  0x788D,
  0x84CB,
  0x8857,
  0x8A72,
  0x93A7,
  0x9AB8,
  0x6D6C,
  0x99A8,
  0x86D9,
  0x57A3,
  0x67FF,
  0x86CE,
  0x920E,
  0x5283,
  0x5687,
  0x5404,
  0x5ED3,
  0x62E1,
  0x64B9,
  0x683C,
  0x6838,
  0x6BBB,
  0x7372,
  0x78BA,
  0x7A6B,
  0x899A,
  0x89D2,
  0x8D6B,
  0x8F03,
  0x90ED,
  0x95A3,
  0x9694,
  0x9769,
  0x5B66,
  0x5CB3,
  0x697D,
  0x984D,
  0x984E,
  0x639B,
  0x7B20,
  0x6A2B,
  0x6A7F,
  0x68B6,
  0x9C0D,
  0x6F5F,
  0x5272,
  0x559D,
  0x6070,
  0x62EC,
  0x6D3B,
  0x6E07,
  0x6ED1,
  0x845B,
  0x8910,
  0x8F44,
  0x4E14,
  0x9C39,
  0x53F6,
  0x691B,
  0x6A3A,
  0x9784,
  0x682A,
  0x515C,
  0x7AC3,
  0x84B2,
  0x91DC,
  0x938C,
  0x565B,
  0x9D28,
  0x6822,
  0x8305,
  0x8431,
  0x7CA5,
  0x5208,
  0x82C5,
  0x74E6,
  0x4E7E,
  0x4F83,
  0x51A0,
  0x5BD2,
  0x520A,
  0x52D8,
  0x52E7,
  0x5DFB,
  0x559A,
  0x582A,
  0x59E6,
  0x5B8C,
  0x5B98,
  0x5BDB,
  0x5E72,
  0x5E79,
  0x60A3,
  0x611F,
  0x6163,
  0x61BE,
  0x63DB,
  0x6562,
  0x67D1,
  0x6853,
  0x68FA,
  0x6B3E,
  0x6B53,
  0x6C57,
  0x6F22,
  0x6F97,
  0x6F45,
  0x74B0,
  0x7518,
  0x76E3,
  0x770B,
  0x7AFF,
  0x7BA1,
  0x7C21,
  0x7DE9,
  0x7F36,
  0x7FF0,
  0x809D,
  0x8266,
  0x839E,
  0x89B3,
  0x8ACC,
  0x8CAB,
  0x9084,
  0x9451,
  0x9593,
  0x9591,
  0x95A2,
  0x9665,
  0x97D3,
  0x9928,
  0x8218,
  0x4E38,
  0x542B,
  0x5CB8,
  0x5DCC,
  0x73A9,
  0x764C,
  0x773C,
  0x5CA9,
  0x7FEB,
  0x8D0B,
  0x96C1,
  0x9811,
  0x9854,
  0x9858,
  0x4F01,
  0x4F0E,
  0x5371,
  0x559C,
  0x5668,
  0x57FA,
  0x5947,
  0x5B09,
  0x5BC4,
  0x5C90,
  0x5E0C,
  0x5E7E,
  0x5FCC,
  0x63EE,
];

export default array1;
