/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable no-shadow */
import SearchCondition from '@/newcar/core/model/searchCondition';
import NEWCAR_DEFINE from '@/newcar/util/define';
import API from '@/common/util/api';

namespace URI {
    /**
     * APIエンドポイント
     * @export
     * @enum {number}
     */
    export class APIEndPoints implements API.IEndPoints {
        public static readonly Digest = '/api_new/digest';

        public static readonly Detail = '/api_new/detail';

        public static readonly Search = '/api/search';

        public static readonly Application = '/api/application';

        public static readonly CorpApplication = '/api/corp-application';

        public static readonly GetAgreement = '/api/agreement/get';

        public static readonly UpdateAgreement = '/api/agreement/update';
    }

    /**
     * URLで設定するパラメーター名
     * @export
     * @enum {number}
     */
    export enum ApiParams {
        /** 検索 フリーワード 設定時は要エンコード */
        Word = 'word',
        /** 検索 ボディタイプ ,(カンマ)区切りでID */
        BodyType = 'body',
        /** 検索 メーカー  ,(カンマ)区切りでID */
        MakerId = 'maker',
        /** 検索 月額料金 ,(カンマ)区切りでID */
        Price = 'price',
        /** リース期間 1〜9 検索とシミュレーション共通 */
        Term = 'term',
        /** 定員 ,(カンマ)区切りでID */
        Capacity = 'cap',
        /** 検索 その他条件 ,(カンマ)区切りでOptionParam Enumの値 */
        Option = 'opt',
        /** 検索 並び順 */
        Order = 'order',
        // 以下はサーバー切り替えで見直し
        /** シミュレーション グレード */
        GradeId = 'grade',
        /** シミュレーション 期間 */
        SimTerm = 'months',
        /** シミュレーション 外装色 */
        BodyColor = 'option_type-1',
        /** シミュレーション シート地
        SeatType = "option_type-2", */
        /** シミュレーション カーナビ */
        CarNavi = 'option_type-3',
        /** シミュレーション ETC */
        Etc = 'option_type-4',
        /** シミュレーション ？？ */
        BackCamera = 'option_type-5',
        /** シミュレーション ？？ */
        FloorMat = 'option_type-6',
        /** シミュレーション 希望ナンバー */
        WantNumber = 'option_type-7',
        /** シミュレーション ドアバイザー */
        DoorVisor = 'option_type-8',
    }

    /**
     * 検索結果の件数と検索条件から遷移先のURIを作成する
     * @export
     * @param {SearchCondition} condition
     * @returns {string}
     */
    export function RESULT_CONDITION_TO_URI(condition: SearchCondition): string {
      const joinURIParams: string[] = [];

      const { word } = condition;
      if (word !== undefined) {
        // フリーワード検索の場合
        joinURIParams.push(`${URI.ApiParams.Word}=${encodeURIComponent(word)}`);
      } else {
        // メーカーが1件以上ある場合
        if (condition.maker.length > 0) {
          joinURIParams.push(`${URI.ApiParams.MakerId}=${condition.maker.join(',')}`);
        }
        // ボディタイプが1件以上ある場合
        if (condition.body.length > 0) {
          joinURIParams.push(`${URI.ApiParams.BodyType}=${condition.body.join(',')}`);
        }

        // 月額料金で生成
        if (condition.price.length > 0) {
          // 期間を設定
          joinURIParams.push(`${URI.ApiParams.Term}=${condition.term}`);
          const priceId: string[] = [];
          for (let j = 0; j < condition.price.length; j += 1) {
            priceId.push(condition.price[j]?.toString());
          }
          joinURIParams.push(`${URI.ApiParams.Price}=${priceId.join(',')}`);
        }

        // 定員が1件以上ある場合
        if (condition.capacity.length > 0) {
          joinURIParams.push(`${URI.ApiParams.Capacity}=${condition.capacity.join(',')}`);
        }

        // オプションが1件以上ある場合
        if (condition.option.length > 0) {
          joinURIParams.push(`${URI.ApiParams.Option}=${condition.option.join(',')}`);
        }
      }
      if (joinURIParams.length === 0) {
        // 無条件
        return NEWCAR_DEFINE.PATH.LINEUP_TOP;
      }
      if (!condition.word
        && condition.maker.length <= 1
        && condition.body.length <= 1
        && condition.price.length === 0
        && condition.capacity.length === 0
        && condition.option.length === 0) {
        // メーカー、ボディタイプが１件のみはパネル表示。ただし１件のみの場合はパネルからDetailへリダイレクト
        if (condition.maker.length === 1 && condition.body.length === 0) {
          // メーカー１件のみの場合
          return NEWCAR_DEFINE.PATH.MAKER_PATH(condition.maker[0]);
        } if (condition.maker.length === 0 && condition.body.length === 1) {
          // ボディタイプ１件のみの場合
          return NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(condition.body[0]);
        } if (condition.maker.length === 1 && condition.body.length === 1) {
          // メーカー１件かつボディタイプ１件のみの場合
          return NEWCAR_DEFINE.PATH.MAKER_BODYTYPE(condition.maker[0], condition.body[0]);
        }
        return NEWCAR_DEFINE.PATH.LINEUP_TOP;
      }
      // 以外はリスト表示、ただし検索結果が1件の場合はリストからDetailにリダイレクト
      return `${NEWCAR_DEFINE.PATH.LIST_ROUTING}?${joinURIParams.join('&')}`;
    }
}
export default URI;
