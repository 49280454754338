type GTMWindow = {
  dataLayer: Array<object>
}

export class GTM {
  static get dataLayer(): Array<object> {
    const defaultDataLayer = ((window as object) as GTMWindow).dataLayer ?? [];
    ((window as object) as GTMWindow).dataLayer = defaultDataLayer;

    return ((window as object) as GTMWindow).dataLayer;
  }
}
