/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */
import { observable, action, computed } from 'mobx';
import { store } from '@/common/core/store/decorator';

import { EntryBreakdown } from '@/newcar/core/store/dataset/entryBreakdown';
import NEWCAR_CONST from '@/newcar/util/const';
import StoreUtil from '@/newcar/core/store/storeUtil';

/**
 * 小計の計算
 * @param entryBreakdown 申込内訳
 */
function subtotal(entryBreakdown: EntryBreakdown) {
  if (!entryBreakdown || !entryBreakdown.term) {
    return 0;
  }

  // グレード料金
  const gradeFee = StoreUtil.taxedTotalFee(
    entryBreakdown.grade,
    NEWCAR_CONST.TERM.TO_MONTHS(entryBreakdown.term),
  ) + StoreUtil.calcTAX(NEWCAR_CONST.BREAKDOWN.CUSTOMER_SUPPORT_FEE);

  // オプション料金
  const optionFee = [
    entryBreakdown.carNavi,
    entryBreakdown.etc,
    entryBreakdown.backCamera,
    entryBreakdown.floorMat,
    entryBreakdown.wantNo,
    entryBreakdown.doorVisor,
  ]
    .filter((opt) => opt && opt.isStandard !== true) // 標準装備は除く
    .concat([entryBreakdown.bodyColor])
    .reduce((acc, cur) => acc + StoreUtil.taxedTotalFee(cur), 0);

  // メンテナンス料金
  let maintenancePrice = 0;
  if (entryBreakdown.maintenancePlan) {
    maintenancePrice = entryBreakdown.maintenancePlan.price;
  }

  // もらえるオプション料金
  const wantCarPrice = entryBreakdown && entryBreakdown.wantCar ? entryBreakdown.wantCar.price : 0;

  // 小計
  return gradeFee + optionFee + maintenancePrice + wantCarPrice;
}

@store()
export default class EntryBreakdownManagerStore {
  @observable entryBreakdown: EntryBreakdown = {};

  @action
  setEntryBreakdown(entryBreakdown: EntryBreakdown): void {
    this.entryBreakdown = entryBreakdown;
  }

  @action
  restoreFromSessionStorage(): void {
    this.entryBreakdown = {};
    try {
      if (sessionStorage.entryBreakdown) {
        this.entryBreakdown = JSON.parse(sessionStorage.entryBreakdown);
      }
    } catch (e) {}
  }

  @action
  saveToSessionStorage(): void {
    try {
      if (this.entryBreakdown) {
        sessionStorage.entryBreakdown = JSON.stringify(this.entryBreakdown);
      }
    } catch (e) {}
  }

  @action
  async clearEntryBreakdown(): Promise<void> {
    try {
      if (this.entryBreakdown) {
        this.entryBreakdown = {};
        sessionStorage.removeItem('entryBreakdown');
      }
    } catch (e) {}
  }

  @action
  updateEntryBreakdown(item: { field: string; payload: any }): void {
    this.entryBreakdown = {
      ...this.entryBreakdown,
      [item.field]: item.payload,
    } as EntryBreakdown;
  }

  @computed
  get getEntryBreakdown(): EntryBreakdown {
    return this.entryBreakdown;
  }

  @computed
  get formData(): EntryBreakdown {
    // TODO
    return this.entryBreakdown;
  }

  /**
   * 契約月数
   */
  @computed
  get months(): number | undefined {
    return NEWCAR_CONST.TERM.TO_MONTHS(
      this.entryBreakdown && this.entryBreakdown.term,
    );
  }

  /**
   * 税込グレード料金
   */
  @computed
  get taxedGradeFee(): number {
    return (
      StoreUtil.taxedTotalFee(
        this.entryBreakdown && this.entryBreakdown.grade,
        NEWCAR_CONST.TERM.TO_MONTHS(
          this.entryBreakdown && this.entryBreakdown.term,
        ),
      ) + StoreUtil.calcTAX(NEWCAR_CONST.BREAKDOWN.CUSTOMER_SUPPORT_FEE)
    );
  }

  /**
   * 各オプション料金
   */
  @computed
  get taxedTotalFeeOfOption(): (optionKey: string, months?: number) => number {
    return (optionKey: string, months?: number): number => {
      const data = this.entryBreakdown
        && (this.entryBreakdown as { [optionKey: string]: any })[optionKey];
      if (!data || data.isStandard === true) {
        return 0;
      }

      return StoreUtil.taxedTotalFee(data, months);
    };
  }

  /**
   * オプション名
   */
  @computed
  get optionName(): (optionKey: string) => string {
    return (optionKey: string): string => {
      const data = this.entryBreakdown
        && (this.entryBreakdown as { [optionKey: string]: any })[optionKey];
      if (!data) {
        return 'なし';
      }
      if (data.isStandard === true) {
        return '標準装備';
      }

      return data.name || 'なし';
    };
  }

  /**
   * 小計
   */
  @computed
  get subtotal(): number {
    return subtotal(this.entryBreakdown);
  }

  /**
   * 月額料金
   */
  @computed
  get monthlyFee(): number {
    return (
      subtotal(this.entryBreakdown)
      + StoreUtil.taxedoOlineDiscount(
        this.entryBreakdown.grade,
        NEWCAR_CONST.TERM.TO_MONTHS(this.entryBreakdown.term),
      )
    );
  }

  /**
   * 仲介手数料
   */
  @computed
  get brokerageFee(): number {
    if (!this.entryBreakdown || !this.entryBreakdown.term) {
      return 0;
    }
    const gradeSupportFee = StoreUtil.taxedSupportFee(
      this.entryBreakdown.grade,
      NEWCAR_CONST.TERM.TO_MONTHS(this.entryBreakdown.term),
    );

    const targetOptions = [
      this.entryBreakdown.carNavi,
      this.entryBreakdown.etc,
      this.entryBreakdown.backCamera,
      this.entryBreakdown.floorMat,
      this.entryBreakdown.wantNo,
      this.entryBreakdown.doorVisor,
    ]
      .filter((opt) => opt && opt.isStandard !== true) // 標準装備は除く
      .concat([this.entryBreakdown.bodyColor]);

    const optionSupportFee = targetOptions.reduce(
      (acc, cur) => acc + StoreUtil.taxedSupportFee(cur),
      0,
    );

    return gradeSupportFee + optionSupportFee;
  }

  /** ネット割 */
  @computed
  get taxedoOlineDiscount(): number {
    return StoreUtil.taxedoOlineDiscount(
      this.entryBreakdown.grade,
      NEWCAR_CONST.TERM.TO_MONTHS(this.entryBreakdown.term),
    );
  }
}

export const entryBreakdownManagerStore = new EntryBreakdownManagerStore();
