/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';
import EntryFormStoreContext from '@/newcar/ui/common/context/EntryFormStoreContext';
import EntryBreakdownManagerStoreContext from '@/newcar/ui/common/context/EntryBreakdownManagerStoreContext';
import LotteryManagerStoreContext from '@/newcar/ui/common/context/LotteryManagerStoreContext';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import SimulationResultManagerStoreContext from '@/newcar/ui/common/context/SimulationResultManagerStoreContext';
import { entryFormStore } from '@/newcar/core/store/entryFormStore';
import { lotteryManagerStore } from '@/newcar/core/store/lotteryManagerStore';
import { entryBreakdownManagerStore } from '@/newcar/core/store/entryBreakdownManagerStore';
import { simulationManagerStore } from '@/newcar/core/store/simulationManagerStore';
import { simulationResultManagerStore } from '@/newcar/core/store/simulationResultManagerStore';

type NestedContextProps = {
  contexts: { context: React.Context<any>; store: any }[];
  children: React.ReactNode;
};

const NestedContext: FC<NestedContextProps> = ({
  contexts,
  children,
}: NestedContextProps) => {
  const renderCustomNestTags = (
    _contexts: { context: React.Context<any>; store: any }[],
  ) => {
    const currentContext = _contexts.pop();
    if (currentContext) {
      const TmpTag = currentContext.context.Provider;
      return (
        <TmpTag value={currentContext.store}>
          {renderCustomNestTags(_contexts)}
        </TmpTag>
      );
    }
    return children;
  };
  return <>{renderCustomNestTags(contexts)}</>;
};

const ContextProvider: FC = ({ children }) => (
  <NestedContext
    // ContextとStoreをこの配列に追加することでReactコンポーネント上でStoreを利用する事ができる
    contexts={[
      {
        context: EntryFormStoreContext,
        store: entryFormStore,
      },
      {
        context: EntryBreakdownManagerStoreContext,
        store: entryBreakdownManagerStore,
      },
      { context: LotteryManagerStoreContext, store: lotteryManagerStore },
      {
        context: SimulationManagerStoreContext,
        store: simulationManagerStore,
      },
      {
        context: SimulationResultManagerStoreContext,
        store: simulationResultManagerStore,
      },
    ]}
  >
    {children}
  </NestedContext>
);

export default ContextProvider;
