/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
// store系
import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';
import CarDigest from '@/common/core/model/carDigest';
import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';

namespace StoreUtil {
  // 同一車種内で人気順で並び替え
  export function comparePopularityScore(a: CarDigest, b: CarDigest): number {
    return a.popularityScore < b.popularityScore ? 1 : -1;
  }

  // ボディタイプがUNSELECTEDの時MINI_CARか OTHERに分類
  export function identifyBodyType(
    bodyType: CARMO_CONST.BODY_TYPE.ID,
    isMiniCar: boolean,
  ): CARMO_CONST.BODY_TYPE.ID {
    if (bodyType === CARMO_CONST.BODY_TYPE.ID.UNSELECTED) {
      if (isMiniCar) {
        return CARMO_CONST.BODY_TYPE.ID.MINI_CAR;
      }
      return CARMO_CONST.BODY_TYPE.ID.OTHER;
    }
    return bodyType;
  }

  export function calcTAX(arg?: number): number {
    const TAX = 1.1;
    if (arg) {
      return arg < 0 ? Math.ceil(arg * TAX) : Math.floor(arg * TAX);
    }
    // undefined時は0
    return 0;
  }

  // 指定グレードの車両本体価格(税込)
  export function calcCarTaxPrice(
    grade: VehicleGrade,
    term: NEWCAR_CONST.TERM.ID,
  ): number {
    const SUPPORT_FEE = calcTAX(100);
    if (term === NEWCAR_CONST.TERM.ID.YEAR_11) {
      return (
        calcTAX(grade.leasingFee132)
        + calcTAX(grade.supportFee132)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee132)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_10) {
      return (
        calcTAX(grade.leasingFee120)
        + calcTAX(grade.supportFee120)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee120)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_9) {
      return (
        calcTAX(grade.leasingFee108)
        + calcTAX(grade.supportFee108)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee108)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_8) {
      return (
        calcTAX(grade.leasingFee96)
        + calcTAX(grade.supportFee96)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee96)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_7) {
      return (
        calcTAX(grade.leasingFee84)
        + calcTAX(grade.supportFee84)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee84)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_6) {
      return (
        calcTAX(grade.leasingFee72)
        + calcTAX(grade.supportFee72)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee72)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_5) {
      return (
        calcTAX(grade.leasingFee60)
        + calcTAX(grade.supportFee60)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee60)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_4) {
      return (
        calcTAX(grade.leasingFee48)
        + calcTAX(grade.supportFee48)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee48)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_3) {
      return (
        calcTAX(grade.leasingFee36)
        + calcTAX(grade.supportFee36)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee36)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_2) {
      return (
        calcTAX(grade.leasingFee24)
        + calcTAX(grade.supportFee24)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee24)
      );
    }
    if (term === NEWCAR_CONST.TERM.ID.YEAR_1) {
      return (
        calcTAX(grade.leasingFee12)
        + calcTAX(grade.supportFee12)
        + SUPPORT_FEE
        + calcTAX(grade.onlineDiscountFee12)
      );
    }
    return 0;
  }

  // 消費税計算

  // 税込リース料取得
  export function taxedLeasingFee(data: any = {}, months?: number): number {
    return calcTAX(data[`leasingFee${months || ''}`]);
  }

  // 税込仲介手数料取得
  export function taxedSupportFee(data: any = {}, months?: number): number {
    return calcTAX(data[`supportFee${months || ''}`]);
  }

  // 税込のリース料 + 仲介手数料を取得
  export function taxedTotalFee(data: any = {}, months?: number): number {
    return taxedLeasingFee(data, months) + taxedSupportFee(data, months);
  }

  // ネット割の割引金額を取得
  export function taxedoOlineDiscount(data: any = {}, months?: number): number {
    return calcTAX(data[`onlineDiscountFee${months || ''}`]);
  }
}
export default StoreUtil;
