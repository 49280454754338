/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import { observable, action, computed } from 'mobx';
import { store } from '@/common/core/store/decorator';
import { SimulationResult, SelectBodyColor, SelectOption } from '@/newcar/core/store/dataset/simulationResult';
import NEWCAR_CONST from '@/newcar/util/const';
import CarDigest from '@/common/core/model/carDigest';
import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';
import CustomerSelection from '@/newcar/core/store/dataset/customerSelection';
import { IMaintenancePlan } from '@/newcar/core/model/maintenance';
import { entryBreakdownManagerStore } from '@/newcar/core/store/entryBreakdownManagerStore';
import { simulationManagerStore } from '@/newcar/core/store/simulationManagerStore';
import { searchCarStore } from '@/newcar/core/store/searchCarStore';
import { WantCarBreakdown } from '@/newcar/core/store/dataset/entryBreakdown';

@store()
export default class SimulationResultManagerStore {
  @observable simulationList: SimulationResult[] = [];

  @observable nowSimulationResult?: SimulationResult = undefined;

  // クエリパラメータで保存済みシミュレーションに含まれているか
  @observable private isSaved = false;

  @computed
  get nowSimulationList(): SimulationResult[] {
    return this.simulationList;
  }

  @computed
  get nowSimulation(): SimulationResult | undefined {
    return this.nowSimulationResult;
  }

  // 保存済みグレード情報
  @computed
  get selectedGrade(): { name: string, sub: string } | undefined {
    if (!this.nowSimulationResult || !this.nowSimulationResult.grade) {
      return undefined;
    }

    const nowGrade = this.nowSimulationResult.grade;
    const subList = [];
    subList.push(nowGrade.engine || 'ガソリン');
    if (nowGrade.kudou) {
      subList.push(nowGrade.kudou);
    }
    if (nowGrade.capacity) {
      subList.push(NEWCAR_CONST.CAPACITY.NAME[nowGrade.capacity]);
    }
    return {
      name: nowGrade?.name || '',
      sub: subList.join(' | '),
    };
  }

  // 保存済みボディカラー情報
  @computed
  get selectedBodyColor(): { bodyColorId?: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, isSelected?: boolean } | undefined {
    if (this.nowSimulationResult && this.nowSimulationResult.bodyColor && this.nowSimulationResult.bodyColor.isSelected) {
      return {
        bodyColorId: this.nowSimulationResult.bodyColor.bodyColorId,
        bodyColorName: this.nowSimulationResult.bodyColor.bodyColorName,
        colorCode1: this.nowSimulationResult.bodyColor.colorCode1,
        colorCode2: this.nowSimulationResult.bodyColor.colorCode2,
        isSelected: this.nowSimulationResult.bodyColor.isSelected,
      };
    }
    return undefined;
  }

  // 保存済みオプション情報
  @computed
  get selectedOption(): string | undefined {
    if (this.nowSimulationResult && this.nowSimulationResult.optionstring) {
      return this.nowSimulationResult.optionstring;
    }
    return '';
  }

  // 保存済みメンテナンスプラン名
  @computed
  get selectedMaintenance(): string | undefined {
    if (this.nowSimulationResult && this.nowSimulationResult.maintenancePlan && this.nowSimulationResult.maintenancePlan.name) {
      return this.nowSimulationResult.maintenancePlan.name;
    }
    return undefined;
  }

  // 保存済み価格
  @computed
  get selectedNomalPrice(): number | undefined {
    if (this.nowSimulationResult && this.nowSimulationResult.totalPrice) {
      return this.nowSimulationResult.totalPrice;
    }
    return undefined;
  }

  // 保存済み賃貸期間
  @computed
  get selectedTerm(): NEWCAR_CONST.TERM.ID {
    if (this.nowSimulationResult && this.nowSimulationResult.customerSelection && this.nowSimulationResult.customerSelection.term) {
      return this.nowSimulationResult.customerSelection.term;
    }
    return NEWCAR_CONST.TERM.ID.YEAR_11;
  }

  // PC版料金シミュレーション用 オリジナルプラン情報返却
  @computed
  get oldOriginalSelection(): {gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID} {
    const result:{gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID} = {};

    const simulationResult = this.nowSimulationResult;
    const selection = (simulationResult) ? simulationResult.customerSelection : undefined;
    if (simulationResult && selection) {
      result.gradeName = (simulationResult && simulationResult.grade) ? simulationResult.grade.name : undefined;

      const regex = /\s\|/gi;
      let optionStr = simulationResult.optionstring;
      if (!optionStr) {
        optionStr = 'なし';
      }
      if (optionStr) {
        result.option = optionStr.replace(regex, '、');
      }

      result.color = (simulationResult && simulationResult.bodyColor) ? simulationResult.bodyColor.bodyColorName : undefined;

      result.maintenance = (simulationResult && simulationResult.maintenancePlan) ? simulationResult.maintenancePlan.name : undefined;

      result.price = (simulationResult && simulationResult.totalPrice) ? simulationResult.totalPrice : undefined;

      result.term = (selection && selection.term) ? selection.term : NEWCAR_CONST.TERM.ID.YEAR_9;
    }

    return result;
  }

  // 保存済みシミュレーション結果から該当のSimulationResultを抽出
  @action
  public changeSimulationResult(customerSelection: CustomerSelection | undefined) {
    this.setSimulationResult(customerSelection);
    return customerSelection;
  }

  @action
  public setSimulationResult(customerSelection: CustomerSelection | undefined) {
    if (customerSelection) {
      // simulationList から該当のシミュレーション結果を探す
      const target = this.simulationList.find((simulation) => {
        // Detail表示直後のdontHaveDoorVisorがundefinedになってしまうので、それ以外を比較
        if (customerSelection && simulation.customerSelection) {
          if (customerSelection.id != simulation.customerSelection.id) { return false; }
          if (customerSelection.masterNumber != simulation.customerSelection.masterNumber) { return false; }
          if (customerSelection.term != simulation.customerSelection.term) { return false; }
          if (customerSelection.engine != simulation.customerSelection.engine) { return false; }
          if (customerSelection.gradeName != simulation.customerSelection.gradeName) { return false; }
          if (customerSelection.kudou != simulation.customerSelection.kudou) { return false; }
          if (customerSelection.mission != simulation.customerSelection.mission) { return false; }
          if (customerSelection.gradeId != simulation.customerSelection.gradeId) { return false; }
          if (customerSelection.bodyColorId != simulation.customerSelection.bodyColorId) { return false; }
          if (customerSelection.carNavi != simulation.customerSelection.carNavi) { return false; }
          if (customerSelection.backCamera != simulation.customerSelection.backCamera) { return false; }
          if (customerSelection.floorMat != simulation.customerSelection.floorMat) { return false; }
          if (customerSelection.doorVisor != simulation.customerSelection.doorVisor) { return false; }
          if (customerSelection.wantNo != simulation.customerSelection.wantNo) { return false; }
          if (customerSelection.wantCar != simulation.customerSelection.wantCar) { return false; }
          if (customerSelection.maintenancePlan != simulation.customerSelection.maintenancePlan) { return false; }
          return true;
        }
        return false;
      });

      if (target) {
        const newSimulationResult = new SimulationResult(target.customerSelection, target.car, target.grade, target.bodyColor, target.totalPrice, target.optionstring);
        if (target.carNavi) {
          newSimulationResult.carNavi = target.carNavi;
        }
        if (target.etc) {
          newSimulationResult.etc = target.etc;
        }
        if (target.backCamera) {
          newSimulationResult.backCamera = target.backCamera;
        }
        if (target.floorMat) {
          newSimulationResult.floorMat = target.floorMat;
        }
        if (target.wantNo) {
          newSimulationResult.wantNo = target.wantNo;
        }
        if (target.doorVisor) {
          newSimulationResult.doorVisor = target.doorVisor;
        }
        if (target.maintenancePlan) {
          newSimulationResult.maintenancePlan = target.maintenancePlan;
        }
        if (target.wantCar) {
          newSimulationResult.wantCar = target.wantCar;
        }
        this.nowSimulationResult = newSimulationResult;
      }
    }
  }

  // 現在表示している保存済み料金シミュレーションを破棄
  @action
  public clearSelection() {
    this.clearSelectionData();
    return true;
  }

  @action
  public clearSelectionData() {
    this.nowSimulationResult = undefined;
  }

  @action
  public restoreSimulationsLocalStorage() {
    // ローカルストレージを使うのでサーバーサイドのタイミングでは処理しない
    if (typeof window === 'undefined') return;

    if (localStorage[NEWCAR_CONST.SIMULATIONRESULT.SIMULATION_RESULT_LOCAL_STORAGE_KEY]) {
      const json = JSON.parse(localStorage[NEWCAR_CONST.SIMULATIONRESULT.SIMULATION_RESULT_LOCAL_STORAGE_KEY]);
      if (json) {
        this.simulationList = [];
        for (const data of json) {
          const simulation = new SimulationResult(
            data.customerSelection,
            data.car,
            data.grade,
            data.bodyColor,
            data.totalPrice,
            data.optionstring,
          );

          if (data.carNavi) {
            simulation.carNavi = data.carNavi;
          }

          if (data.etc) {
            simulation.etc = data.etc;
          }

          if (data.backCamera) {
            simulation.backCamera = data.backCamera;
          }

          if (data.floorMat) {
            simulation.floorMat = data.floorMat;
          }

          if (data.wantNo) {
            simulation.wantNo = data.wantNo;
          }

          if (data.doorVisor) {
            simulation.doorVisor = data.doorVisor;
          }

          if (data.maintenancePlan) {
            simulation.maintenancePlan = data.maintenancePlan;
          }

          if (data.wantCar) {
            simulation.wantCar = data.wantCar;
          }

          this.simulationList.push(simulation);
        }
      }
    }
  }

  /**
   * Action:料金シミュレーションの追加
   * @param {customerSelection: CustomerSelection, carDigest: CarDigest, grade: VehicleGrade, totalPrice: number, optionstring: string | undefined, option: {carNavi?: SelectOption, etc?: SelectOption, backCamera?: SelectOption, floorMat?: SelectOption, wantNo?: SelectOption, doorVisor?: SelectOption, maintenancePlan?: IMaintenancePlan, wantCar?: SelectOption}} data
   * @returns
   * @memberof SimulationResultManager
   */
  @action
  public addSimulation(data: {customerSelection: CustomerSelection | undefined, carDigest: CarDigest | undefined, grade: VehicleGrade | undefined, bodyColor: SelectBodyColor | undefined, totalPrice: number | undefined, optionstring: string | undefined, option: {carNavi?: SelectOption, etc?: SelectOption, backCamera?: SelectOption, floorMat?: SelectOption, wantNo?: SelectOption, doorVisor?: SelectOption, maintenancePlan?: IMaintenancePlan, wantCar?: SelectOption}}) {
    this.updateSimulation(data);
    return data;
  }

  /**
   * @param {customerSelection: CustomerSelection, carDigest: CarDigest, grade: VehicleGrade, totalPrice: number, optionstring: string | undefined, option: {carNavi?: SelectOption, etc?: SelectOption, backCamera?: SelectOption, floorMat?: SelectOption, wantNo?: SelectOption, doorVisor?: SelectOption, maintenancePlan?: IMaintenancePlan, wantCar?: SelectOption}} data
   * @memberof SimulationResultManager
   */
  @action
  public updateSimulation(data: {customerSelection: CustomerSelection | undefined, carDigest: CarDigest | undefined, grade: VehicleGrade | undefined, bodyColor: SelectBodyColor | undefined, totalPrice: number | undefined, optionstring: string | undefined, option: {carNavi?: SelectOption, etc?: SelectOption, backCamera?: SelectOption, floorMat?: SelectOption, wantNo?: SelectOption, doorVisor?: SelectOption, maintenancePlan?: IMaintenancePlan, wantCar?: SelectOption}}) {
    // ローカルストレージを使うのでサーバーサイドのタイミングでは処理しない
    if (typeof window === 'undefined') return;

    const savedata: SimulationResult = new SimulationResult(data.customerSelection, data.carDigest, data.grade, data.bodyColor, data.totalPrice, data.optionstring);
    if (data.option.carNavi) {
      savedata.carNavi = data.option.carNavi;
    }
    if (data.option.etc) {
      savedata.etc = data.option.etc;
    }
    if (data.option.backCamera) {
      savedata.backCamera = data.option.backCamera;
    }
    if (data.option.floorMat) {
      savedata.floorMat = data.option.floorMat;
    }
    if (data.option.wantNo) {
      savedata.wantNo = data.option.wantNo;
    }
    if (data.option.doorVisor) {
      savedata.doorVisor = data.option.doorVisor;
    }
    if (data.option.maintenancePlan) {
      savedata.maintenancePlan = data.option.maintenancePlan;
    }
    if (data.option.wantCar) {
      savedata.wantCar = data.option.wantCar;
    }

    this.simulationList = [];
    if (localStorage[NEWCAR_CONST.SIMULATIONRESULT.SIMULATION_RESULT_LOCAL_STORAGE_KEY]) {
      const json = JSON.parse(localStorage[NEWCAR_CONST.SIMULATIONRESULT.SIMULATION_RESULT_LOCAL_STORAGE_KEY]);
      if (json) {
        for (const data of json) {
          const simulation = new SimulationResult(
            data.customerSelection,
            data.car,
            data.grade,
            data.bodyColor,
            data.totalPrice,
            data.optionstring,
          );

          if (data.carNavi) {
            simulation.carNavi = data.carNavi;
          }

          if (data.etc) {
            simulation.etc = data.etc;
          }

          if (data.backCamera) {
            simulation.backCamera = data.backCamera;
          }

          if (data.floorMat) {
            simulation.floorMat = data.floorMat;
          }

          if (data.wantNo) {
            simulation.wantNo = data.wantNo;
          }

          if (data.doorVisor) {
            simulation.doorVisor = data.doorVisor;
          }

          if (data.maintenancePlan) {
            simulation.maintenancePlan = data.maintenancePlan;
          }

          if (data.wantCar) {
            simulation.wantCar = data.wantCar;
          }

          this.simulationList.push(simulation);
        }
      }
    }
    this.simulationList.push(savedata);

    // ローカルストレージに保存
    localStorage.setItem(NEWCAR_CONST.SIMULATIONRESULT.SIMULATION_RESULT_LOCAL_STORAGE_KEY, JSON.stringify(this.simulationList));
  }

  /**
   * Action:保存済み料金シミュレーションの削除
   *
   * @param {({customerSelection: CustomerSelection | undefined, masterNumber: string})} data
   * @returns
   * @memberof SimulationResultManager
   */
  @action
  public removeSimulation(data: {customerSelection: CustomerSelection | undefined, masterNumber: string}) {
    this.deleteSimulation(data);
    return data;
  }

  @action
  public deleteSimulation(data: {customerSelection: CustomerSelection | undefined, masterNumber: string}) {
    // 配列の順番を確認
    const nowCustomerSelection = data.customerSelection;
    const nowMasterNumber = data.masterNumber;

    const newList: SimulationResult[] = [];

    for (let i = 0; i < this.simulationList.length; i += 1) {
      const simulation = this.simulationList[i];
      // Detail表示直後のdontHaveDoorVisorがundefinedになってしまうので、それ以外を比較
      if (nowCustomerSelection && simulation.customerSelection) {
        let result = true;
        if (nowMasterNumber != simulation.masterNumber) { result = false; }
        if (nowCustomerSelection.id != simulation.customerSelection.id) { result = false; }
        if (nowCustomerSelection.term != simulation.customerSelection.term) { result = false; }
        if (nowCustomerSelection.engine != simulation.customerSelection.engine) { result = false; }
        if (nowCustomerSelection.gradeName != simulation.customerSelection.gradeName) { result = false; }
        if (nowCustomerSelection.kudou != simulation.customerSelection.kudou) { result = false; }
        if (nowCustomerSelection.mission != simulation.customerSelection.mission) { result = false; }
        if (nowCustomerSelection.gradeId != simulation.customerSelection.gradeId) { result = false; }
        if (nowCustomerSelection.bodyColorId != simulation.customerSelection.bodyColorId) { result = false; }
        if (nowCustomerSelection.carNavi != simulation.customerSelection.carNavi) { result = false; }
        if (nowCustomerSelection.backCamera != simulation.customerSelection.backCamera) { result = false; }
        if (nowCustomerSelection.floorMat != simulation.customerSelection.floorMat) { result = false; }
        if (nowCustomerSelection.doorVisor != simulation.customerSelection.doorVisor) { result = false; }
        if (nowCustomerSelection.wantNo != simulation.customerSelection.wantNo) { result = false; }
        if (nowCustomerSelection.wantCar != simulation.customerSelection.wantCar) { result = false; }
        if (nowCustomerSelection.maintenancePlan != simulation.customerSelection.maintenancePlan) { result = false; }
        if (!result) {
          newList.push(simulation);
        }
      }
    }

    // 削除
    this.simulationList = newList;
    // ローカルストレージに保存
    localStorage.setItem(NEWCAR_CONST.SIMULATIONRESULT.SIMULATION_RESULT_LOCAL_STORAGE_KEY, JSON.stringify(this.simulationList));
  }

  /**
   * 保存済み料金シミュレーションから申込情報を作成
   *
   * @param {SimulationResult} data
   * @memberof SimulationResultManager
   */
  @action
  public saveEntryBreakdownFromSimulationResult(data: SimulationResult) {
    entryBreakdownManagerStore.clearEntryBreakdown();

    let memo = `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_ORIGINAL}`;

    // 対象車種更新
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'car', payload: data.car });
    // 対象グレード更新
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'grade', payload: data.grade });

    // ボディカラー
    if (data.bodyColor) {
      entryBreakdownManagerStore.updateEntryBreakdown({
        field: 'bodyColor',
        payload: {
          id: data.bodyColor.bodyColorId,
          name: data.bodyColor.bodyColorName,
          leasingFee: data.bodyColor.leasingFee,
          supportFee: data.bodyColor.supportFee,
        },
      });
      if (!data.bodyColor.isSelected) {
        memo += '|ボディカラー：未選択';
      }
    } else {
      memo += '|ボディカラー：未選択';
    }

    // カーナビ
    if (data.carNavi) {
      entryBreakdownManagerStore.updateEntryBreakdown({
        field: 'carNavi',
        payload: {
          id: data.carNavi.id,
          name: data.carNavi.name,
          isStandard: data.carNavi.isStandard,
          leasingFee: data.carNavi.leasingFee,
          supportFee: data.carNavi.supportFee,
        },
      });
    }

    // ETC
    if (data.etc) {
      entryBreakdownManagerStore.updateEntryBreakdown({
        field: 'etc',
        payload: {
          id: data.etc.id,
          name: data.etc.name,
          isStandard: data.etc.isStandard,
          leasingFee: data.etc.leasingFee,
          supportFee: data.etc.supportFee,
        },
      });
    }

    // バックカメラ
    if (data.backCamera) {
      entryBreakdownManagerStore.updateEntryBreakdown({
        field: 'backCamera',
        payload: {
          id: data.backCamera.id,
          name: data.backCamera.name,
          isStandard: data.backCamera.isStandard,
          leasingFee: data.backCamera.leasingFee,
          supportFee: data.backCamera.supportFee,
        },
      });
    }

    // フロアマット
    if (data.floorMat) {
      entryBreakdownManagerStore.updateEntryBreakdown({
        field: 'floorMat',
        payload: {
          id: data.floorMat.id,
          name: data.floorMat.name,
          isStandard: data.floorMat.isStandard,
          leasingFee: data.floorMat.leasingFee,
          supportFee: data.floorMat.supportFee,
        },
      });
    }

    // 希望ナンバー
    if (data.wantNo) {
      entryBreakdownManagerStore.updateEntryBreakdown({
        field: 'wantNo',
        payload: {
          id: data.wantNo.id,
          name: data.wantNo.name,
          isStandard: data.wantNo.isStandard,
          leasingFee: data.wantNo.leasingFee,
          supportFee: data.wantNo.supportFee,
        },
      });
    }

    // ドアバイザー
    if (data.customerSelection) {
      if (data.customerSelection.dontHaveDoorVisor) {
        memo += '|ドアバイザー：装着不可';
      } else if (data.doorVisor) {
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'doorVisor',
          payload: {
            id: data.doorVisor.id,
            name: data.doorVisor.name,
            isStandard: data.doorVisor.isStandard,
            leasingFee: data.doorVisor.leasingFee,
            supportFee: data.doorVisor.supportFee,
          },
        });
      }
    }

    // もらえるオプション
    if (data.wantCar && data.wantCar.price) {
      const wantCar = new WantCarBreakdown();
      wantCar.price = data.wantCar.price;

      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'wantCar', payload: wantCar });
      memo += '|もらえるオプション：希望';
    }

    // 賃貸期間
    if (data.customerSelection && data.customerSelection.term) {
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'term', payload: data.customerSelection.term });
    }

    // メンテナンス
    if (data.maintenancePlan) {
      if (data.maintenancePlan.id === undefined || data.maintenancePlan.id === NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED) {
        memo += '|メンテ：未選択';
      } else if (data.maintenancePlan.id === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) {
        memo += '|メンテ：プラチナ';
      } else if (data.maintenancePlan.id === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
        memo += '|メンテ：ゴールド';
      } else if (data.maintenancePlan.id === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
        memo += '|メンテ：シルバー';
      } else if (data.maintenancePlan.id === NEWCAR_CONST.MAINTENANCE.PLAN.NONE) {
        memo += '|メンテ：なし';
      }
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'maintenancePlan', payload: data.maintenancePlan });
    } else {
      memo += '|メンテ：未選択';
    }

    // 備考欄
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'memo', payload: memo });

    // セッションストレージに保存
    entryBreakdownManagerStore.saveToSessionStorage();
  }

  /**
   * 現在のシミュレーションが保存済みシミュレーションに含まれているか確認する
   *
   * @readonly
   * @type {boolean}
   * @memberof SimulationResultManager
   */
  @computed
  get checkIsSaved(): boolean {
    const { nowCustomerSelection } = simulationManagerStore;

    const target = this.simulationList.find((simulation) => {
      // Detail表示直後のdontHaveDoorVisorがundefinedになってしまうので、それ以外を比較
      if (nowCustomerSelection && simulation.customerSelection) {
        if (nowCustomerSelection.masterNumber != simulation.masterNumber) { return false; }
        if (nowCustomerSelection.id != simulation.customerSelection.id) { return false; }
        if (nowCustomerSelection.term != simulation.customerSelection.term) { return false; }
        if (nowCustomerSelection.engine != simulation.customerSelection.engine) { return false; }
        if (nowCustomerSelection.gradeName != simulation.customerSelection.gradeName) { return false; }
        if (nowCustomerSelection.kudou != simulation.customerSelection.kudou) { return false; }
        if (nowCustomerSelection.mission != simulation.customerSelection.mission) { return false; }
        if (nowCustomerSelection.gradeId != simulation.customerSelection.gradeId) { return false; }
        if (nowCustomerSelection.bodyColorId != simulation.customerSelection.bodyColorId) { return false; }
        if (nowCustomerSelection.carNavi != simulation.customerSelection.carNavi) { return false; }
        if (nowCustomerSelection.etc != simulation.customerSelection.etc) { return false; }
        if (nowCustomerSelection.backCamera != simulation.customerSelection.backCamera) { return false; }
        if (nowCustomerSelection.floorMat != simulation.customerSelection.floorMat) { return false; }
        if (nowCustomerSelection.doorVisor != simulation.customerSelection.doorVisor) { return false; }
        if (nowCustomerSelection.wantNo != simulation.customerSelection.wantNo) { return false; }
        if (nowCustomerSelection.wantCar != simulation.customerSelection.wantCar) { return false; }
        if (nowCustomerSelection.maintenancePlan != simulation.customerSelection.maintenancePlan) { return false; }
        return true;
      }
      return false;
    });

    return (target != undefined);
  }

  @computed
  get isSavedWithParameter():boolean {
    return this.isSaved;
  }

  /**
   * クエリパラメータで保存済みシミュレーションに含まれているか確認する
   */
  @action
  public checkIsSavedWithParameter(data: {id: string, query: any}): {isSaved: boolean, simulationResult?: SimulationResult} {
    if (!data.id || !data.query) { return { isSaved: false }; }
    const { id } = data;
    const { query } = data;

    const customerSelection = new CustomerSelection();
    // 車種ID
    customerSelection.id = id;

    if (query.g) {
      if (searchCarStore.allCarDetails != undefined) {
        const resultCar = (searchCarStore.allCarDetails.find((car) => (car.id == data.id)));

        if (resultCar != undefined && resultCar.vehicleGrades != undefined) {
          const grades = resultCar.vehicleGrades;
          // グレードIDのが変更になったので、駆動以下を該当するものに変更する
          const targetGrade = (grades.find((grade) => (grade.id == query.g)));

          if (targetGrade) {
            // グレードID
            if (targetGrade.id) {
              customerSelection.gradeId = targetGrade.id;
            }
            // グレード名称
            if (targetGrade.name) {
              customerSelection.gradeName = targetGrade.name;
            }
            // 駆動
            if (targetGrade.kudou) {
              customerSelection.kudou = NEWCAR_CONST.KUDOU.NAME_TO_ID(targetGrade.kudou);
            }
            // ミッション
            if (targetGrade.mission) {
              customerSelection.mission = targetGrade.mission.includes('MT') ? 'MT' : 'AT';
            }
            // エンジン種別
            if (targetGrade.engine) {
              customerSelection.engine = NEWCAR_CONST.ENGINE.NAME_TO_ID(targetGrade.engine);
            } else {
              customerSelection.engine = NEWCAR_CONST.ENGINE.ID.IS_GAS;
            }
          }
        }
      }
    }

    // 期間
    if (query.t) {
      customerSelection.term = Number(query.t);
    }
    // グレードID
    if (query.g) {
      customerSelection.gradeId = query.g;
    }
    // ボディカラー
    if (query.b) {
      customerSelection.bodyColorId = query.b;
    }
    // カーナビ
    if (query.c) {
      customerSelection.carNavi = query.c;
    } else {
      customerSelection.carNavi = '100001';
    }
    // ETC
    if (query.e) {
      customerSelection.etc = query.e;
    } else {
      customerSelection.etc = '100003';
    }
    // バックカメラ
    if (query.b2) {
      customerSelection.backCamera = query.b2;
    } else {
      customerSelection.backCamera = '100002';
    }
    // フロアマット
    if (query.f) {
      customerSelection.floorMat = query.f;
    } else {
      customerSelection.floorMat = '100004';
    }
    // ドアバイザー
    if (query.d) {
      customerSelection.doorVisor = query.d;
    } else {
      customerSelection.doorVisor = '100006';
    }
    // ドアバイザー無しの車両
    if (query.d2) {
      customerSelection.dontHaveDoorVisor = true;
    } else {
      customerSelection.dontHaveDoorVisor = false;
    }
    // 希望ナンバー
    if (query.w) {
      customerSelection.wantNo = query.w;
    } else {
      customerSelection.wantNo = '100005';
    }
    // もらえるオプション
    if (query.w2) {
      customerSelection.wantCar = query.w2;
    } else {
      customerSelection.wantCar = 'no';
    }
    // メンテナンスプラン
    if (query.m) {
      customerSelection.maintenancePlan = Number(query.m);
    } else {
      customerSelection.maintenancePlan = NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED;
    }
    // マスタ番号
    if (query.m2) {
      customerSelection.masterNumber = query.m2;
    }

    const target = this.simulationList.find((simulation) => {
      if (customerSelection && simulation.customerSelection) {
        if (customerSelection.masterNumber != simulation.customerSelection.masterNumber) { return false; }
        if (customerSelection.id != simulation.customerSelection.id) { return false; }
        if (customerSelection.term != simulation.customerSelection.term) { return false; }
        if (customerSelection.gradeId != simulation.customerSelection.gradeId) { return false; }
        if (customerSelection.bodyColorId != simulation.customerSelection.bodyColorId) { return false; }
        if (customerSelection.carNavi != simulation.customerSelection.carNavi) { return false; }
        if (customerSelection.backCamera != simulation.customerSelection.backCamera) { return false; }
        if (customerSelection.floorMat != simulation.customerSelection.floorMat) { return false; }
        if (customerSelection.doorVisor != simulation.customerSelection.doorVisor) { return false; }
        if (customerSelection.wantNo != simulation.customerSelection.wantNo) { return false; }
        if (customerSelection.wantCar != simulation.customerSelection.wantCar) { return false; }
        if (customerSelection.maintenancePlan != simulation.customerSelection.maintenancePlan) { return false; }
        return true;
      }
      return false;
    });

    if (target != undefined) {
      this.loadSavedInfo({ isSaved: true, simulationResult: target });
      return { isSaved: true, simulationResult: target };
    }
    this.loadSavedInfo({ isSaved: false });
    return { isSaved: false };
  }

  @action
  public loadSavedInfo(data: {isSaved: boolean, simulationResult?: SimulationResult}) {
    this.isSaved = data.isSaved;
    if (data.isSaved && data.simulationResult) {
      this.nowSimulationResult = data.simulationResult;
      // userselect更新
      if (data.simulationResult.customerSelection) {
        simulationManagerStore.changeUserSelect(data.simulationResult.customerSelection);
      }
    }
  }
}

export const simulationResultManagerStore = new SimulationResultManagerStore();
