import { configure } from 'mobx';

/**
 * MobXの初期設定
 */
let isMobXConfigured = false;
export const configureMobX = (): void => {
  if (!isMobXConfigured) {
    configure({
      useProxies: 'never', // IE11がProxyAPIをサポートしていないため利用しない
    });
    isMobXConfigured = true;
  }
};
