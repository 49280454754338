/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { observable, action, computed } from 'mobx';
import { store } from '@/common/core/store/decorator';
import SearchCondition from '@/newcar/core/model/searchCondition';
import NEWCAR_CONST from '@/newcar/util/const';
import CARMO_CONST from '@/common/util/const';
import { AxiosResponse } from 'axios';
import Log from '@/common/util/log';
import API from '@/common/util/api';
import URI from '@/newcar/util/uri';
import CarDigest from '@/common/core/model/carDigest';
import CarDetail from '@/common/core/model/carDetail';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';
import BodyColorInfo from '@/common/core/model/catalog/bodyColorInfo';
import OptionFee from '@/common/core/model/catalog/optionFee';
import OptionInfo from '@/common/core/model/catalog/optionInfo';
import ProductAPI from '@/newcar/util/productAPI';

/**
 * 車種 概要
 * @class SearchCarStore
 */
@store({ persist: true, name: 'SearchCarStore' })
export default class SearchCarStore {
  /** state:全車種CarDigestリスト */
  @observable public allCarDigests: CarDigest[] = [];

  /** state:全車種Detailリスト */
  @observable public allCarDetails?: VehicleTypes[] = [];

  /** state:全車種BodyColorリスト */
  @observable public allBodyColorList?: BodyColorInfo[] = [];

  /** state:全車種オプション情報リスト */
  @observable public allOptionInfoList?: OptionInfo[] = [];

  /** state:全車種オプション料金リスト */
  @observable public allOptionFeeList?: OptionFee[] = [];

  /** state:検索結果リスト */
  @observable public resultList: CarDigest[] = [];

  /** state:こだわり用次条件 */
  @observable public nextCondition: SearchCondition = new SearchCondition();

  /** state:検索履歴リスト */
  @observable public searchHistoryList: SearchCondition[] = [];

  /** state:閲覧履歴リスト */
  @observable public browsingList: CarDigest[] = [];

  /** 最後に閲覧した車種のCarDigest */
  @observable public lastViewCarDigestData: CarDigest | undefined = undefined;

  /** 最後に閲覧した車種のDetail */
  @observable public lastViewDetailData: VehicleTypes | undefined = undefined;

  /**
   * 最後に閲覧した車種のCarDigest
   * @readonly
   * @memberof SearchCarStore
   */
  @computed
  get lastViewCarDigest(): CarDigest | undefined {
    return this.lastViewCarDigestData;
  }

  /**
   * 最後に閲覧した車種のDetail
   * @readonly
   * @memberof SearchCarStore
   */
  @computed
  get lastViewDetail(): VehicleTypes | undefined {
    return this.lastViewDetailData;
  }

  /**
   * メーカーID件数リスト
   * 販売停止中は除外
   * @readonly
   * @memberof SearchCarStore
   */
  get countAllMaker(): { [key: number]: number; } {
    const count: { [key: number]: number; } = [];
    // 全件リストを対象に
    for (const targetCar of this.allCarDigests) {
      if (targetCar.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE) {
        const id: number = targetCar.makerId;
        // 連想配列で該当メーカーIDをインクリメント。初回は存在しないので1を設定（null+1=null）
        count[id] = (count[id]) ? count[id] + 1 : 1;
      }
    }
    return count;
  }

  /**
   * BodyTypeID件数リスト
   * 販売停止中は除外
   * @readonly
   * @memberof SearchCarStore
   */
  get countAllBody(): { [key: number]: number; } {
    const count: { [key: number]: number; } = [];
    // 全件リストを対象に
    this.allCarDigests.forEach((targetCar) => {
      if (targetCar.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE) {
        const id: number = targetCar.bodyType;
        // 連想配列で該当メーカーIDをインクリメント。初回は存在しないので1を設定（null+1=null）
        count[id] = (count[id]) ? count[id] + 1 : 1;
        // 軽自動車・ハッチバック、その他は二重カウント
        if (targetCar.isMiniCar) {
          count[CARMO_CONST.BODY_TYPE.ID.MINI_CAR] = (count[CARMO_CONST.BODY_TYPE.ID.MINI_CAR]) ? count[CARMO_CONST.BODY_TYPE.ID.MINI_CAR] + 1 : 1;
        } else if (targetCar.bodyType === CARMO_CONST.BODY_TYPE.ID.STATION_WAGON || targetCar.bodyType === CARMO_CONST.BODY_TYPE.ID.UNSELECTED) {
          // 軽自動車でも無く、ボディタイプがステーションワゴンか未設定の場合、その他にカウント
          count[CARMO_CONST.BODY_TYPE.ID.OTHER] = (count[CARMO_CONST.BODY_TYPE.ID.OTHER]) ? count[CARMO_CONST.BODY_TYPE.ID.OTHER] + 1 : 1;
        }
        if (targetCar.isHatchBack) {
          count[CARMO_CONST.BODY_TYPE.ID.HATCH_BACK] = (count[CARMO_CONST.BODY_TYPE.ID.HATCH_BACK]) ? count[CARMO_CONST.BODY_TYPE.ID.HATCH_BACK] + 1 : 1;
        }
      }
    });
    return count;
  }

  /**
   * メーカー別の全BodyTypeID件数リスト
   * 販売停止中は除外
   * @readonly
   * @memberof SearchCarStore
   */
  get countAllMakerBody(): { [key: number]: number; } {
    const count: { [key: number]: number; } = [];
    // 全件リストを対象に
    this.allCarDigests.forEach((targetCar) => {
      if (targetCar.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE) {
        const { makerId } = targetCar;
        const bodyId: number = targetCar.bodyType;
        // 連想配列で該当メーカーIDをインクリメント。初回は存在しないので1を設定（null+1=null）
        let id: number = makerId * 100 + bodyId;
        count[id] = (count[id]) ? count[id] + 1 : 1;
        // 軽自動車・ハッチバック、その他は二重カウント
        if (targetCar.isMiniCar) {
          id = makerId * 100 + CARMO_CONST.BODY_TYPE.ID.MINI_CAR;
          count[id] = (count[id]) ? count[id] + 1 : 1;
        } else if (targetCar.bodyType === CARMO_CONST.BODY_TYPE.ID.STATION_WAGON || targetCar.bodyType === CARMO_CONST.BODY_TYPE.ID.UNSELECTED) {
          // 軽自動車でも無く、ボディタイプがステーションワゴンか未設定の場合、その他にカウント
          id = makerId * 100 + CARMO_CONST.BODY_TYPE.ID.OTHER;
          count[id] = (count[id]) ? count[id] + 1 : 1;
        }
        if (targetCar.isHatchBack) {
          id = makerId * 100 + CARMO_CONST.BODY_TYPE.ID.HATCH_BACK;
          count[id] = (count[id]) ? count[id] + 1 : 1;
        }
      }
    });
    return count;
  }

  /**
   * こだわり条件件数リスト
   * 現在の条件に条件を追加時の件数
   * メーカー、ボディタイプ、月額料金、定員は最初の１件選択だけは絞り込みだが、
   * 以降はOR条件で追加。その他条件はAND条件で絞り込み
   * @readonly
   * @memberof SearchCarStore
   */
  get countNextCondition(): {
    makerCount: {
        [key: number]: number;
    };
    bodyCount: {
        [key: number]: number;
    };
    priceCount: {
        [key: number]: number;
    };
    capacityCount: {
        [key: number]: number;
    };
    optionCount: {
      [key: string]: number;
    };
    nextCount: number;
    } {
    // 返却用のカウンター変数を定義
    const makerCount: { [key: number]: number; } = [];
    const bodyCount: { [key: number]: number; } = [];
    const priceCount: { [key: number]: number; } = [];
    const capacityCount: { [key: number]: number; } = [];
    const optionCount: { [key: string]: number; } = {};
    let nextCount = 0;

    // 全件リストから条件に該当するかチェックしてNextリストに追加
    this.allCarDigests.forEach((targetCar) => {
      // 販売中止はスキップ
      if (targetCar.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE) {
        // ID変換 ボディタイプ
        const targetBodyIdList: number[] = [];
        if (targetCar.bodyType !== CARMO_CONST.BODY_TYPE.ID.UNSELECTED) {
          targetBodyIdList.push(targetCar.bodyType);
        }
        // 軽自動車は二重カウント
        if (targetCar.isMiniCar) {
          targetBodyIdList.push(CARMO_CONST.BODY_TYPE.ID.MINI_CAR);
        } else if (targetCar.bodyType === CARMO_CONST.BODY_TYPE.ID.STATION_WAGON || targetCar.bodyType === CARMO_CONST.BODY_TYPE.ID.UNSELECTED) {
          // 軽自動車でも無く、ボディタイプがステーションワゴンか未設定の場合、その他にカウント
          targetBodyIdList.push(CARMO_CONST.BODY_TYPE.ID.OTHER);
        }

        // ID変換 料金
        let targetPrice = 0;
        switch (this.nextCondition.term) {
          case NEWCAR_CONST.TERM.ID.YEAR_11: targetPrice = targetCar.taxedMinPrice11; break;
          // todo税抜きで他も持つ？
          case NEWCAR_CONST.TERM.ID.YEAR_10: targetPrice = targetCar.taxedMinPrice10; break;
          case NEWCAR_CONST.TERM.ID.YEAR_9: targetPrice = targetCar.taxedMinPrice9; break;
          case NEWCAR_CONST.TERM.ID.YEAR_8: targetPrice = targetCar.taxedMinPrice8; break;
          case NEWCAR_CONST.TERM.ID.YEAR_7: targetPrice = targetCar.taxedMinPrice7; break;
          case NEWCAR_CONST.TERM.ID.YEAR_6: targetPrice = targetCar.taxedMinPrice6; break;
          case NEWCAR_CONST.TERM.ID.YEAR_5: targetPrice = targetCar.taxedMinPrice5; break;
          case NEWCAR_CONST.TERM.ID.YEAR_4: targetPrice = targetCar.taxedMinPrice4; break;
          case NEWCAR_CONST.TERM.ID.YEAR_3: targetPrice = targetCar.taxedMinPrice3; break;
          case NEWCAR_CONST.TERM.ID.YEAR_2: targetPrice = targetCar.taxedMinPrice2; break;
          case NEWCAR_CONST.TERM.ID.YEAR_1: targetPrice = targetCar.taxedMinPrice1; break;
          default:
        }
        const targetPriceId: NEWCAR_CONST.PRICE.ID = NEWCAR_CONST.PRICE.TERM_PRICE_TO_ID(this.nextCondition.term, targetPrice);

        // ID変換 定員
        const targetCapacityIdList: number[] = [];
        if (targetCar.has4SeatOrLess) {
          targetCapacityIdList.push(NEWCAR_CONST.CAPACITY.ID.UNDER_4);
        }
        if (targetCar.has5Seat) {
          targetCapacityIdList.push(NEWCAR_CONST.CAPACITY.ID.SEATER_5);
        }
        if (targetCar.has6Seat) {
          targetCapacityIdList.push(NEWCAR_CONST.CAPACITY.ID.SEATER_6);
        }
        if (targetCar.has7Seat) {
          targetCapacityIdList.push(NEWCAR_CONST.CAPACITY.ID.SEATER_7);
        }
        if (targetCar.has8SeatOrMore) {
          targetCapacityIdList.push(NEWCAR_CONST.CAPACITY.ID.OVER_8);
        }

        // ID変換 その他条件
        const targetOptionIdList: string[] = [];
        if (targetCar.hasHybrid) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_HYBRID);
        }
        if (targetCar.has4WD) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_4WD);
        }
        if (targetCar.hasMT) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_MT);
        }
        if (targetCar.hasPowerSlideDoor) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_POWER_SLIDE_DOOR);
        }
        if (targetCar.hasKeylessEntry) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_KEYLESS_ENTRY);
        }
        if (targetCar.hasSmartKey) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SMART_KEY);
        }
        if (targetCar.hasCruiseControl) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_CRUISE_CTRL);
        }
        if (targetCar.hasAutomaticBrake) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_AUTO_BRAKE);
        }
        if (targetCar.hasPedalSupport) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SUPPORT_PEDAL);
        }
        if (targetCar.hasLaneDepartureAlert) {
          targetOptionIdList.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SUPPORT_LANE);
        }

        // メーカーが該当かチェック
        const isTargetMaker = (this.nextCondition.maker.length === 0) || (this.nextCondition.maker.find((id) => (id === targetCar.makerId)));
        // ボディタイプが該当かチェック
        const isTargetBody = (this.nextCondition.body.length === 0) || (this.nextCondition.body.find((id) => {
          for (let j = 0; j < targetBodyIdList.length; j += 1) {
            if (targetBodyIdList[j] === id) {
              return true;
            }
          }
          return false;
        }));
        // 料金が該当かチェック
        const isTargetPrice = (this.nextCondition.price.length === 0) || (this.nextCondition.price.find((id) => (id === targetPriceId)));
        // 定員が該当かチェック
        const isTargetCapacity = (this.nextCondition.capacity.length === 0) || (this.nextCondition.capacity.find((id) => {
          for (let j = 0; j < targetCapacityIdList.length; j += 1) {
            if (targetCapacityIdList[j] === id) {
              return true;
            }
          }
          return false;
        }));

        // オプションが該当かチェック
        const isTargetOption = (this.nextCondition.option.length === 0) || (this.nextCondition.option.find((id) => {
          for (let j = 0; j < targetOptionIdList.length; j += 1) {
            if (targetOptionIdList[j] === id) {
              return true;
            }
          }
          return false;
        }));

        // 次候補メーカーのカウント
        if (isTargetBody && isTargetPrice && isTargetCapacity && isTargetOption) {
          const { makerId } = targetCar;
          makerCount[makerId] = (makerCount[makerId]) ? makerCount[makerId] + 1 : 1;
        }
        // 次候補ボディタイプのカウント
        if (isTargetMaker && isTargetPrice && isTargetCapacity && isTargetOption) {
          for (let j = 0; j < targetBodyIdList.length; j += 1) {
            bodyCount[targetBodyIdList[j]] = (bodyCount[targetBodyIdList[j]]) ? bodyCount[targetBodyIdList[j]] + 1 : 1;
          }
        }
        // 次候補料金のカウント
        if (isTargetMaker && isTargetBody && isTargetCapacity && isTargetOption) {
          priceCount[targetPriceId] = (priceCount[targetPriceId]) ? priceCount[targetPriceId] + 1 : 1;
        }
        // 次候補定員のカウント
        if (isTargetMaker && isTargetBody && isTargetPrice && isTargetOption) {
          for (let j = 0; j < targetCapacityIdList.length; j += 1) {
            capacityCount[targetCapacityIdList[j]] = (capacityCount[targetCapacityIdList[j]]) ? capacityCount[targetCapacityIdList[j]] + 1 : 1;
          }
        }
        // 次候補オプションのカウント
        if (isTargetMaker && isTargetBody && isTargetPrice && isTargetCapacity) {
          for (let j = 0; j < targetOptionIdList.length; j += 1) {
            optionCount[targetOptionIdList[j]] = (optionCount[targetOptionIdList[j]]) ? optionCount[targetOptionIdList[j]] + 1 : 1;
          }
        }
        // 現条件での総件数カウント
        if (isTargetMaker && isTargetBody && isTargetPrice && isTargetCapacity && isTargetOption) {
          nextCount += 1;
        }
      }
    });

    return {
      makerCount, bodyCount, priceCount, capacityCount, optionCount, nextCount,
    };
  }

  /**
   * 検索履歴の取得
   * @readonly
   * @type {{ name: string, uri: string }[]}
   * @memberof SearchCarStore
   */
  get searchHistory(): Array<{ name: string, uri: string }> {
    let result: Array<{ name: string, uri: string }> = [];
    // 全件リストを対象に
    for (const targetHistory of this.searchHistoryList) {
      const joinName: string[] = [];
      const joinURIParams: string[] = [];
      if (targetHistory.word !== undefined) {
        // フリーワード検索の場合
        // 上記だけだとコンパイルエラーになるので一旦const格納で別途undefinedを除外して格納
        const ret: string | undefined = targetHistory.word;
        if (ret !== undefined) {
          joinName.push(ret);
          joinURIParams.push(`${URI.ApiParams.Word}=${encodeURIComponent(ret)}`);
        }
      } else {
        // メーカーで生成
        const makerId: string[] = [];
        for (let j = 0; j < targetHistory.maker.length; j += 1) {
          joinName.push(CARMO_CONST.MAKER.NAME(targetHistory.maker[j]));
          makerId.push(targetHistory.maker[j].toString());
        }
        // メーカーが1件以上ある場合
        if (makerId.length > 0) {
          joinURIParams.push(`${URI.ApiParams.MakerId}=${makerId.join(',')}`);
        }
        // ボディタイプで生成
        const bodyId: string[] = [];
        for (let j = 0; j < targetHistory.body.length; j += 1) {
          joinName.push(CARMO_CONST.BODY_TYPE.NAME(targetHistory.body[j]));
          bodyId.push(targetHistory.body[j].toString());
        }
        // ボディタイプが1件以上ある場合
        if (bodyId.length > 0) {
          joinURIParams.push(`${URI.ApiParams.BodyType}=${bodyId.join(',')}`);
        }

        // 月額料金で生成
        if (targetHistory.price.length > 0) {
          // 期間を設定
          joinName.push(NEWCAR_CONST.TERM.NAME[targetHistory.term]);
          joinURIParams.push(`${URI.ApiParams.Term}=${targetHistory.term}`);
          const priceId: string[] = [];
          for (let j = 0; j < targetHistory.price.length; j += 1) {
            joinName.push(NEWCAR_CONST.PRICE.NAME[targetHistory.price[j]]);
            priceId.push(targetHistory.price[j].toString());
          }
          joinURIParams.push(`${URI.ApiParams.Price}=${priceId.join(',')}`);
        }

        // 定員で生成
        const capacityId: string[] = [];
        for (let j = 0; j < targetHistory.capacity.length; j += 1) {
          joinName.push(NEWCAR_CONST.CAPACITY.NAME[targetHistory.capacity[j]]);
          capacityId.push(targetHistory.capacity[j].toString());
        }
        // 定員が1件以上ある場合
        if (capacityId.length > 0) {
          joinURIParams.push(`${URI.ApiParams.Capacity}=${capacityId.join(',')}`);
        }

        // その他の条件で生成
        const searchOptId: string[] = [];
        for (let j = 0; j < targetHistory.option.length; j += 1) {
          joinName.push(NEWCAR_CONST.SEARCH_OPTION.NAME(targetHistory.option[j]));
          searchOptId.push(targetHistory.option[j].toString());
        }
        // 定員が1件以上ある場合
        if (searchOptId.length > 0) {
          joinURIParams.push(`${URI.ApiParams.Option}=${searchOptId.join(',')}`);
        }
      }
      // 生成結果をカンマ区切りの文字列化して格納
      const target: string | undefined = targetHistory.resultURI;
      let resulturi = '';
      if (target !== undefined) {
        resulturi = target;
      }
      // 履歴の生成
      const add = { name: joinName.join('、'), uri: resulturi };
      // 同一条件が過去にあったら過去のを削除して追加
      result = result.filter((x) => x.uri !== add.uri);
      result.push(add);
    }

    return result;
  }

  get masterNumber() : string | undefined {
    if (this.allCarDigests && this.allCarDigests[0]) {
      return this.allCarDigests[0].masterNumber;
    }
    return undefined;
  }

  /**
   * action:車種情報全件取得
   * @param {CarDigest[]} list
   * @memberof SearchCarStore
   */
  @action
  public setAllList(result: any[]): void {
    const CarDigestList: CarDigest[] = result[0];

    // メーカー名称を割り当て
    this.allCarDigests = CarDigestList.map((element) => {
      if (element.makerId) { element.makerName = CARMO_CONST.MAKER.NAME(element.makerId); }
      return element;
    });

    const detailList: CarDetail = result[1];
    this.allCarDetails = detailList.vehicleTypes;
    this.allBodyColorList = detailList.bodyColorInfo;
    this.allOptionInfoList = detailList.optionInfo;
    this.allOptionFeeList = detailList.optionFees;
  }

  /**
   * action:車種情報を1件のみセット
   * @param CarDigest
   * @param CarDetail
   * @memberof SearchCarStore
   */
  @action
  public setSingleCarInfo(digest: CarDigest, detail: CarDetail): void {
    // メーカー名称を割り当て
    const newCarDigest = { ...digest, makerName: CARMO_CONST.MAKER.NAME(digest.makerId) };
    this.allCarDigests = [newCarDigest];

    const detailList: CarDetail = detail;
    this.allCarDetails = detailList.vehicleTypes;
    this.allBodyColorList = detailList.bodyColorInfo;
    this.allOptionInfoList = detailList.optionInfo;
    this.allOptionFeeList = detailList.optionFees;
  }

  /**
   * action:表示Detail設定、閲覧履歴追加
   * @param {string} addid
   * @memberof SearchCarStore
   */
  @action
  public setViewDetail(addid: string): void {
    // Detailの設定
    if (this.allCarDetails) {
      this.lastViewDetailData = this.allCarDetails.find((x) => x.id.toString() === addid);
    }

    // 閲覧履歴設定
    // 同一車種IDが過去にあったら削除
    this.browsingList = this.browsingList.filter((x) => {
      if (x) {
        return x.id__normalized.toString() !== addid;
      }
      return false;
    });
    const targetCar = this.allCarDigests.filter((x) => x.id__normalized.toString() === addid)[0];
    // 最終閲覧として登録
    this.lastViewCarDigestData = targetCar;
    // 末尾に追加
    this.browsingList.push(targetCar);
  }

  /**
   * 現在の検索条件に当てはまる車をresultListに追加
   * @param {CARMO_CONST.MAKER.ID} makerId
   * @memberof SearchCarStore
   */
  @action
  public async search(condition: SearchCondition): Promise<void> {
    // ワードの有無で切り分け
    if (condition.word) {
      // 結果リストの初期化
      this.resultList = [];
      let result: string[];
      // 検索結果の車種データをresultListに格納
      await API.get(API.API_SETTINGS.API.name, URI.APIEndPoints.Search, `?q=${condition.word}`)
        .then((res: AxiosResponse) => {
          // 結果を格納
          result = Object.values(res).map((carId) => carId);
          result.forEach((value: string) => {
            const resultCar = this.allCarDigests.filter((x) => x.id__normalized === value)[0];
            if (resultCar.minPrice > 0) this.resultList.push(resultCar);
          });
        });
    } else {
      // 結果リストの初期化
      this.resultList = [];
      // 条件検索の場合、全件リストから条件に該当するかチェックして検索結果リストに追加
      this.allCarDigests.forEach((targetCar) => {
        // 販売中止はスキップ
        if (targetCar.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE) {
          // メーカーが該当か確認
          let isTargetMaker = false;
          if (condition.maker.length > 0) {
            if (condition.maker.includes(targetCar.makerId)) isTargetMaker = true;
          } else {
            // メーカーが検索条件では無い場合もtrueに
            isTargetMaker = true;
          }

          // ボディタイプが該当か確認
          let isTargetBody = false;
          if (condition.body.length > 0) {
            for (let x = 0; x < condition.body.length; x += 1) {
              if (condition.body[x] === targetCar.bodyType) {
                isTargetBody = true;
                break;
              } else if (targetCar.isMiniCar && condition.body[x] === CARMO_CONST.BODY_TYPE.ID.MINI_CAR) {
                isTargetBody = true;
                break;
              } else if (targetCar.isHatchBack && condition.body[x] === CARMO_CONST.BODY_TYPE.ID.HATCH_BACK) {
                isTargetBody = true;
                break;
              } else if ((targetCar.bodyType === CARMO_CONST.BODY_TYPE.ID.STATION_WAGON || targetCar.bodyType === CARMO_CONST.BODY_TYPE.ID.UNSELECTED)
                && !targetCar.isMiniCar && condition.body[x] === CARMO_CONST.BODY_TYPE.ID.OTHER) {
                isTargetBody = true;
                break;
              }
            }
          } else {
            // ボディタイプが検索条件では無い場合もtrueに
            isTargetBody = true;
          }

          // 料金が該当か確認
          let isTargetPrice = false;
          if (condition.price.length > 0) {
            const taxedMinPrice = targetCar.taxedMinPrice11;
            for (let x = 0; x < condition.price.length; x += 1) {
              const isBW_10_20K = (taxedMinPrice >= 10000 && taxedMinPrice < 20000);
              const isBW_20_30K = (taxedMinPrice >= 20000 && taxedMinPrice < 30000);
              const isBW_30_40K = (taxedMinPrice >= 30000 && taxedMinPrice < 40000);
              const isBW_40_50K = (taxedMinPrice >= 40000 && taxedMinPrice < 50000);
              const isOVER_50K = (taxedMinPrice >= 50000); // todo 11年以外が来たらまた変わる

              if ((condition.price[x] === NEWCAR_CONST.PRICE.ID.BW_10_20K && isBW_10_20K)
                || (condition.price[x] === NEWCAR_CONST.PRICE.ID.BW_20_30K && isBW_20_30K)
                || (condition.price[x] === NEWCAR_CONST.PRICE.ID.BW_30_40K && isBW_30_40K)
                || (condition.price[x] === NEWCAR_CONST.PRICE.ID.BW_40_50K && isBW_40_50K)
                || (condition.price[x] === NEWCAR_CONST.PRICE.ID.OVER_50K && isOVER_50K)
              ) {
                // 該当すればbreak
                isTargetPrice = true;
                break;
              }
            }
          } else {
            isTargetPrice = true;
          }

          // 定員が該当か確認
          let isTargetCapacity = false;
          if (condition.capacity.length > 0) {
            for (let x = 0; x < condition.capacity.length; x += 1) {
              if ((condition.capacity[x] === NEWCAR_CONST.CAPACITY.ID.UNDER_4 && targetCar.has4SeatOrLess)
                || (condition.capacity[x] === NEWCAR_CONST.CAPACITY.ID.SEATER_5 && targetCar.has5Seat)
                || (condition.capacity[x] === NEWCAR_CONST.CAPACITY.ID.SEATER_6 && targetCar.has6Seat)
                || (condition.capacity[x] === NEWCAR_CONST.CAPACITY.ID.SEATER_7 && targetCar.has7Seat)
                || (condition.capacity[x] === NEWCAR_CONST.CAPACITY.ID.OVER_8 && targetCar.has8SeatOrMore)
              ) {
                // 該当すればbreak
                isTargetCapacity = true;
                break;
              }
            }
          } else {
            isTargetCapacity = true;
          }

          // その他条件が該当か確認
          let isTargetOptions = false;
          if (condition.option.length > 0) {
            for (let x = 0; x < condition.option.length; x += 1) {
              if ((condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_4WD && targetCar.has4WD)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_AUTO_BRAKE && targetCar.hasAutomaticBrake)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_CRUISE_CTRL && targetCar.hasCruiseControl)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_HYBRID && targetCar.hasHybrid)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_KEYLESS_ENTRY && targetCar.hasKeylessEntry)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_MT && targetCar.hasMT)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_POWER_SLIDE_DOOR && targetCar.hasPowerSlideDoor)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SMART_KEY && targetCar.hasSmartKey)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SUPPORT_LANE && targetCar.hasLaneDepartureAlert)
                || (condition.option[x] === NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SUPPORT_PEDAL && targetCar.hasPedalSupport)
              ) {
                // 該当すればbreak
                isTargetOptions = true;
                break;
              }
            }
          } else {
            isTargetOptions = true;
          }

          // has〜はcondition側がundefined or falseか、対象車種側がtrueか
          if (isTargetMaker && isTargetBody && isTargetPrice && isTargetCapacity && isTargetOptions) {
            this.resultList.push(targetCar);
          }
        }
      });
    }

    // 結果が0件では無い場合
    if (this.resultList.length > 0) {
      // URIを追加して検索履歴に追加
      condition.resultURI = URI.RESULT_CONDITION_TO_URI(condition);
      this.searchHistoryList.push(condition);

      // 抽出したデータを指定順序に並び替え
      if (condition.orderBy === NEWCAR_CONST.SORT.POP) {
        // 人気順でソート
        this.resultList.sort((a: CarDigest, b: CarDigest) => (a.popularityScore < b.popularityScore ? 1 : -1));
      } else if (condition.orderBy === NEWCAR_CONST.SORT.ATOZ) {
        // 50音順でソート
        this.resultList.sort((a: CarDigest, b: CarDigest) => (a.name > b.name ? 1 : -1));
      } else {
        // 値段でソート
        this.resultList.sort((a: CarDigest, b: CarDigest) => (a.minPrice > b.minPrice ? 1 : -1));
      }
    }

    Log.trace(`action search end. result count:${this.resultList.length}`);
  }

  /**
   * action:こだわり用次条件設定
   * @param {SearchCondition} condition
   * @memberof SearchCarStore
   */
  @action
  public nextCount = (condition: SearchCondition): void => {
    this.nextCondition = condition;
  }

  /**
   * action:車種CarDigest、車種Detail全件取得
   * @returns
   * @memberof SearchCarStore
   */
  @action
  public getAllList = async (): Promise<any[]> => {
    const getAllCarDigest = new Promise((resolve /** reject? */) => {
      ProductAPI.getDigest()
        .then((res: AxiosResponse) => {
          // 結果を格納
          resolve(res);
        });
    });
    const getAllDetail = new Promise((resolve /** reject? */) => {
      ProductAPI.getDetail()
        .then((res: AxiosResponse) => {
          // 結果を格納
          resolve(res);
        });
    });
    let res: any[] = [];
    await Promise.all([getAllCarDigest, getAllDetail]).then((results) => {
      res = results;
    }).catch((/** reject? */) => {
      // todo
      Log.error(new Error('Network Error!'));
    });
    return res;
  }

  /**
   * action:汎用検索
   * @param {SearchCondition} condition
   * @returns
   * @memberof SearchCarStore
   */
  @action
  public searchCondition(condition: SearchCondition): void {
    // return condition;
    this.search(condition);
  }

  /**
  * action:こだわり次条件検索
  * @param {SearchCondition} condition
  * @returns
  * @memberof SearchCarStore
  */
  @action
  public nextConditionCount(condition: SearchCondition): SearchCondition {
    this.nextCondition = condition;
    // return condition;
    return this.nextCondition;
  }

  /**
   * action:車種閲覧
   * @returns
   * @memberof SearchCarStore
   */
  @action
  public async viewCarDetail(id: string) {
    this.setViewDetail(id);
    return id;
  }

  /** 車種全件をallCarDigestにセット */
  @action
  public setAllCarDigests(cardigests: CarDigest[]): void {
    this.allCarDigests = cardigests;
  }
}

export const searchCarStore = new SearchCarStore();
