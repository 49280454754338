import array1 from '@/common/util/convertibleUnicode/array1';
import array2 from '@/common/util/convertibleUnicode/array2';
import array3 from '@/common/util/convertibleUnicode/array3';
import array4 from '@/common/util/convertibleUnicode/array4';
import array5 from '@/common/util/convertibleUnicode/array5';
import array6 from '@/common/util/convertibleUnicode/array6';

const convertibleUnicode = array1.concat(array2, array3, array4, array5, array6);

const checkConvertibleToShiftJIS = (s: string): boolean => {
  for (let i = 0; i < s.length; i += 1) {
    if (convertibleUnicode.indexOf(s.charCodeAt(i)) === -1) {
      return false;
    }
  }
  return true;
};

export default checkConvertibleToShiftJIS;
