import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';

/**
 * 検索条件
 * @export
 * @class SearchCondition
 */
export default class SearchCondition {
    /**
     * 検索を実施した場合の遷移先URI
     * @type {(string | undefined)}
     * @memberof SearchCondition
     */
    public resultURI: string | undefined = undefined;

    /**
     * ワード検索
     * @type {(string | undefined)}
     * @memberof SearchCondition
     */
    public word: string | undefined = undefined;

    /**
     * ワード検索結果
     * @type {(string[] | undefined)}
     * @memberof SearchCondition
     */
    public wordResult: string[] = [];

    /**
     * メーカー
     * @type {CARMO_CONST.MAKER.ID[]}
     * @memberof SearchCondition
     */
    public maker: CARMO_CONST.MAKER.ID[] = [];

    /**
     * ボディタイプ
     * @type {CARMO_CONST.BODY_TYPE.ID[]}
     * @memberof SearchCondition
    */
    public body: CARMO_CONST.BODY_TYPE.ID[] = [];

    /**
     * 期間
     * @type {NEWCAR_CONST.TERM.ID}
     * @memberof SearchCondition
     */
    public term: NEWCAR_CONST.TERM.ID = NEWCAR_CONST.TERM.ID.YEAR_11;

    /**
     * 価格
     * @type {NEWCAR_CONST.PRICE.ID[]}
     * @memberof SearchCondition
     */
    public price: NEWCAR_CONST.PRICE.ID[] = [];

    /**
     * 定員
     * @type {NEWCAR_CONST.PRICE.ID[]}
     * @memberof SearchCondition
     */
    public capacity: NEWCAR_CONST.CAPACITY.ID[] = [];

    /**
     * その他条件
     * @type {NEWCAR_CONST.SEARCH_OPTION.ID[]}
     * @memberof SearchCondition
     */
    public option: NEWCAR_CONST.SEARCH_OPTION.ID[] = [];

    /**
     * 並び順
     * @type {(boolean | undefined)}
     * @memberof SearchCondition
     */
    public orderBy: NEWCAR_CONST.SORT | undefined = undefined;
}
