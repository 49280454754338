/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosError, AxiosResponse } from 'axios';
import Log from '@/common/util/log';
import { IS_MOCKUP } from '@/common/core/const';

namespace API {
  export type I_API_NAME = 'CDN' | 'API';
  // FIXME エントリーポイントで一回だけ初期化するようにする
  export const API_SETTINGS = {
    CDN_API: {
      // CDN APIエンドポイント（例：http://127.0.0.1:8080、https://car-mo.jp
      name: IS_MOCKUP ? 'CarmoApiCDNMockup' : 'CarmoApiCDN',
      endpoint: process.env.CARMO_PRODUCT_API_BASE_PATH,
    },
    API: {
      // Amplify APIエンドポイント（モックアップの場合はCDN APIエンドポイントと同じ）
      name: IS_MOCKUP ? 'CarmoApiMockup' : 'CarmoApi',
      endpoint: process.env.GATSBY_CARMO_API_BASE_PATH,
    },
  };

  /** エンドポイント定数文字列をカテゴリ（ newcar, usedcar 等 ）ごとに使用するためのインターフェース */
  // tslint:disable-next-line: no-empty-interface
  export interface IEndPoints {}

  /**
   * APIメソッド
   * @export
   * @enum {number}
   */
  export class EndPoints implements API.IEndPoints {
    public static readonly Addresses = '/addresses';

    public static readonly EmailSignup = '/api/email/signup';

    public static readonly ContactData = '/api/postbaseinfo';

    public static readonly DrawCampaign = '/api/draw-campaign';

    public static readonly Application = '/api/usedcar/application';

    public static readonly Search = '/api/usedcar/search';
  }

  const apiComplete = (response: AxiosResponse) => {
    Log.trace(`status:${response.status.toString()}`);
    Log.trace('response data:::');
    Log.trace(response.data);
  };

  const apiError = (error: AxiosError) => {
    if (error.response) {
      Log.trace(`data${error.response.data}`);
      Log.trace(`status${error.response.status}`);
      Log.trace(`headers${error.response.headers}`);
    } else {
      Log.trace(`message${error.message}`);
    }
  };

  /**
   * APIのパスを生成
   *
   * @param endpoint
   * @param params
   * @private
   */
  const _generatePath = (endpoint: API.IEndPoints, params?: string) => {
    let path = '';
    path = endpoint.toString();
    return path + (params === undefined ? '' : params);
  };

  /**
   * LambdaAPI Get
   * @export
   * @param {string} apiName 呼び出しAPI名
   * @param {NewcarURI.APIEndPoints} endpoint
   * @param {string} [params]
   *
   * @returns
   */
  export async function get(
    apiName: string,
    endpoint: API.IEndPoints,
    params = '',
  ): Promise<any> {
    // const uri = "https://httpbin.org/status/500";
    try {
      const path = _generatePath(endpoint, encodeURI(params));
      Log.trace(`Call API:${apiName}, path:${path}`);
      let data: any;
      await axios
        .get(API_SETTINGS.API.endpoint + path)
        .then((response: AxiosResponse) => {
          // 正常時の共通処理
          apiComplete(response);
          // 返却用に格納
          data = response.data;
        })
        .catch((e: any) => {
          Log.error(e);
          // 異常時の共通処理
          apiError(e);
        });
      return await data;
    } catch (error) {
      Log.error(error);
      throw error;
    }
  }

  export type IAddress = {
    postCode: string;
    isOffice: boolean;
    prefecture: string;
    prefectureKana: string;
    city: string;
    cityKana: string;
    town: string;
    townKana: string;
    block: string;
    blockKana: string;
  };

  export async function findAddresses(params = ''): Promise<IAddress[]> {
    const path = API.EndPoints.Addresses;
    const ret = await get('API', path, params);
    return ret;
  }

  export async function findCarsfromFreeText(word: string): Promise<String[]> {
    const query = `?q=${encodeURI(word)}`;
    const path = API.EndPoints.Search;
    const ret = await get('API', path, query);
    return ret;
  }

  /** s
   * AXIOSでのPOST処理
   *
   * @param endpoint APIパス
   * @param body     リクエストボディ
   * @param params   パラメータ
   */
  export async function axiosPost(
    endpoint: API.IEndPoints,
    body?: any,
    params = '',
  ): Promise<AxiosResponse | undefined> {
    try {
      const path = _generatePath(endpoint, encodeURI(params));
      Log.trace(`Call API:${endpoint}, path:${path}`);
      let responseToBeReturned: AxiosResponse | undefined;
      await axios
        .post(process.env.GATSBY_CARMO_API_BASE_PATH + path, body)
        .then((response: AxiosResponse) => {
          // 正常時の共通処理
          apiComplete(response);
          // 返却用に格納
          responseToBeReturned = response;
        })
        .catch((e) => {
          Log.error(e);
          // 異常時の共通処理
          apiError(e);
        });
      return await responseToBeReturned;
    } catch (error) {
      Log.error(error);
      throw error;
    }
  }

  /**
   * API POST
   *
   * @param endpoint APIパス
   * @param body     リクエストボディ
   * @param params   パラメータ
   */
  export async function post(
    endpoint: API.IEndPoints,
    body?: any,
    params = '',
  ): Promise<any> {
    const response = await axiosPost(endpoint, body, params);
    return response;
  }

  /**
   * AXIOSでのPOST処理
   * エラー時はエラーを返す
   *
   * @param endpoint APIパス
   * @param body     リクエストボディ
   * @param params   パラメータ
   */
  // export async function postReturnE(
  //   endpoint: API.IEndPoints,
  //   body?: any,
  //   params = '',
  // ): Promise<AxiosResponse | undefined> {
  //   try {
  //     params = encodeURI(params);
  //     const path = _generatePath(endpoint, params);
  //     Log.trace(`Call API:${API_NAME}, path:${path}`);
  //     let responseToBeReturned: AxiosResponse | undefined;

  //     AmplifyAPI.configure(API_SETTINGS);
  //     await AmplifyAPI.post(API_NAME, path, { body, response: true })
  //       .then((response: AxiosResponse) => {
  //         // 正常時の共通処理
  //         apiComplete(response);
  //         // 返却用に格納
  //         responseToBeReturned = response;
  //       })
  //       .catch((e: any) => {
  //         Log.error(e);
  //         // 異常時の共通処理
  //         apiError(e);
  //         if (e.response) {
  //           responseToBeReturned = e.response;
  //         }
  //       });
  //     return await responseToBeReturned;
  //   } catch (error) {
  //     Log.error(error);
  //     throw error;
  //   }
  // }
}

export default API;
