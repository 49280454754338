/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from '@/common/core/store/decorator';
import { observable, action, computed } from 'mobx';
import Cookies from 'js-cookie';
import { EntryBreakdown } from '@/newcar/core/store/dataset/entryBreakdown';
import NEWCAR_CONST from '@/newcar/util/const';

/**
 * 審査申込フォーム
 */
@store({ persist: true, name: 'newcarEntryForm' })
export default class EntryFormStore {
  // Flags
  @observable complete = false;

  @observable sendContactData = false;

  // Personal Info
  @observable lastName = '';

  @observable firstName = '';

  @observable lastKana = '';

  @observable firstKana = '';

  @observable gender: number | null = null;

  @observable year: number | string | null = '';

  @observable month: number | string = '';

  @observable day: number | string = '';

  @observable email = '';

  @observable phoneNumber = '';

  @observable mobileNumber = '';

  @observable postalCode = '';

  @observable address1 = '';

  @observable address2 = '';

  @observable address3 = '';

  // --------------
  @observable prefectures = '';

  @observable city = '';

  @observable street = '';

  @observable addressKana = '';

  // --------------
  @observable question1Answer = '';

  @observable question1Sub1Answer = '';

  @observable question1Sub2Answer = '';

  @observable question2Answer = '';

  @observable question3Answer = '';

  @observable question4Answer = '';

  @observable question4AnswerFree = '';

  @observable question5Answer = '';

  @observable couponCode = '';

  @observable note = '';

  @observable purpose = '';

  @observable trigger = '';

  // Review Info
  @observable isSameAddress = ''

  @observable addressPostalCode = '';

  @observable addressAddress1 = '';

  @observable addressAddress2 = '';

  @observable addressAddress3 = '';

  @observable residenceYear = '';

  // --------------
  @observable residencePrefectures = '';

  @observable residenceCity = '';

  @observable residenceStreet = '';

  @observable residenceAddressKana = '';

  // --------------
  @observable residenceStatus: number | null = null;

  @observable holder = '';

  @observable maritalStatus: number | null = null;

  @observable livingPersons: number | null = null;

  @observable livingMinors: number | null = null;

  @observable businessType: number | null = null;

  @observable businessAddressType: number | null = null;

  @observable industryType = '';

  @observable subIndustryType = '';

  @observable businessYears: number | null = null;

  @observable income: number | null = null;

  @observable company = '';

  @observable position = '';

  @observable companyPostalCode = '';

  @observable companyAddress1 = '';

  @observable companyAddress2 = '';

  @observable companyAddress3 = '';

  // --------------
  @observable officePrefectures = '';

  @observable officeCity = '';

  @observable officeStreet = '';

  @observable officeAddressKana = '';

  // --------------
  @observable companyPhoneNumber: string | null = null;

  @observable licenseNumber: string | null = null;

  @observable cvSource?: string;

  @observable utmSource = '';

  @observable utmMedium = '';

  @observable lineId = '';

  // 審査申込ID
  @observable reservationId: number | null = null;

  // 対象キャンペーン一覧
  @observable campaigns: NEWCAR_CONST.CAMPAIGNS.NAME[] = [];

  @observable entryBreakdown: EntryBreakdown = {};

  @action
  setSendContactData(data: EntryFormStore['sendContactData']): void {
    this.sendContactData = data;
  }

  @action
  setLastName(lastName: EntryFormStore['lastName']): void {
    this.lastName = lastName;
  }

  @action
  setGender(gender: EntryFormStore['gender']): void {
    this.gender = gender;
  }

  @action
  setYear(year: EntryFormStore['year']): void {
    this.year = year;
  }

  @action
  setQuestion3Answer(question3Answer: EntryFormStore['question3Answer']): void {
    this.question3Answer = question3Answer;
  }

  @computed
  get getForm(): EntryFormStore {
    return this;
  }

  @computed
  get birthDateToISOString(): string | undefined {
    if (this.year && this.month && this.day) {
      return new Date(
        // 日付ゼロ埋め
        `${this.year}-${this.month}-${this.day}T00:00:00Z`.replace(/\d+/g, (m) => '0'.substr(m.length - 1) + m),
      ).toISOString();
    }
    return undefined;
  }

  @action
  setEntryBreakdown(entryBreakdown: EntryFormStore['entryBreakdown']): void {
    this.entryBreakdown = entryBreakdown;
  }

  @action
  async addCampaigns(
    ...campaigns: NEWCAR_CONST.CAMPAIGNS.NAME[]
  ): Promise<NEWCAR_CONST.CAMPAIGNS.NAME[]> {
    if (Array.isArray(this.campaigns)) {
      return Array.from(new Set([...this.campaigns, ...campaigns]));
    }
    return this.campaigns;
  }

  @action
  updateForm(payload: { data: any, field: string }): void {
    (this as any)[payload.field] = payload.data;
  }

  /**
   * CVの流入元を取得
   */
  @action
  async retrieveCVSource(): Promise<string | undefined> {
    const ans = Cookies.get('satr_source');
    this.updateForm({ data: ans, field: 'cvSource' });
    return ans;
  }

  /**
   * UTMパラメータを取得
   */
  @action
  async retrieveUtmParams(): Promise<{ utmSource: string; utmMedium: string; lineId: string;}> {
    let utmSourceList = '';
    let utmMediumList = '';
    let lineIdList = '';

    try {
      if (localStorage) {
        utmSourceList = localStorage.getItem('utmSourceList') || '[]';
        utmMediumList = localStorage.getItem('utmMediumList') || '[]';
        lineIdList = localStorage.getItem('lineIdList') || '';
      } else {
        utmSourceList = Cookies.get('utmSourceList') || '[]';
        utmMediumList = Cookies.get('utmMediumList') || '[]';
        lineIdList = Cookies.get('lineIdList') || '';
      }

      this.updateForm({ data: utmSourceList, field: 'utmSource' });
      this.updateForm({ data: utmMediumList, field: 'utmMedium' });
      this.updateForm({ data: lineIdList, field: 'lineId' });

      return {
        utmSource: (JSON.parse(utmSourceList) as string[]).join(', '),
        utmMedium: (JSON.parse(utmMediumList) as string[]).join(', '),
        lineId: (JSON.parse(lineIdList) as string[]).join(', '),
      };
    } catch (e) {
      this.updateForm({ data: '', field: 'utmSource' });
      this.updateForm({ data: '', field: 'utmMedium' });
      this.updateForm({ data: '', field: 'lineId' });

      return {
        utmSource: '',
        utmMedium: '',
        lineId: '',
      };
    }
  }
}

export const entryFormStore = new EntryFormStore();
