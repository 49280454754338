import NEWCAR_CONST from '@/newcar/util/const';

/**
 * 利用者の選択結果
 * @export
 * @class CustomerSelection
 */
export default class CustomerSelection {
    /**
     * 車種ID
     * @type {string}
     * @memberof CustomerSelection
     */
    public id = '';

    /**
     * 期間
     * @type {NEWCAR_CONST.TERM.ID}
     * @memberof CustomerSelection
     */
    public term?: NEWCAR_CONST.TERM.ID;

    /**
     * エンジン種別
     * @type {NEWCAR_CONST.ENGINE.ID}
     * @memberof CustomerSelection
     */
    public engine?: NEWCAR_CONST.ENGINE.ID;

    /**
     * 駆動方式
     * @type {NEWCAR_CONST.KUDOU.ID}
     * @memberof CustomerSelection
     */
    public kudou?: NEWCAR_CONST.KUDOU.ID;

    /**
     * ミッション
     * @type {'AT' | 'MT'}
     * @memberof CustomerSelection
     */
    public mission?: 'AT' | 'MT';

    /**
     * グレードID
     * @type {number}
     * @memberof CustomerSelection
     */
    public gradeId?: number;

    /**
     * グレード名称
     * @type {string}
     * @memberof CustomerSelection
     */
    public gradeName?: string;

    /**
     * 発注グレード名
     * @type {string}
     * @memberof CustomerSelection
     */
    public orderName?: string;

    /**
     * ボディーカラーID
     * @type {string}
     * @memberof CustomerSelection
     */
    public bodyColorId?: string;

    // カーナビ
    public carNavi?: string | undefined;

    // ETC
    public etc?: string | undefined;

    // バックカメラ
    public backCamera?: string | undefined;

    // フロアマット
    public floorMat?: string | undefined;

    // ドアバイザー
    public doorVisor?: string | undefined;

    // ドアバイザー無しの車両
    public dontHaveDoorVisor?: boolean;

    // 希望ナンバー
    public wantNo?: string | undefined;

    // もらえるオプション
    public wantCar?: string;

    // メンテナンスプラン
    public maintenancePlan?: NEWCAR_CONST.MAINTENANCE.PLAN;

    // マスタ番号
    public masterNumber?: string;
}
